import { DialogModalType } from "features/common/components/DialogModal/DialogModal";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectUserToken } from "./authSlice";

export interface AuthProps {
    children: JSX.Element | JSX.Element[];
}

export const Auth = ({ children }: AuthProps) => {
    const user = useSelector(selectUserToken);
    const location = useLocation();
    const isLogin = location.pathname.includes('login') || location.pathname.includes('logout');

    const {
        dialog: { setModalShow }
    } = useUIBehaviour();

    useEffect(() => {
        if (user) return;
        if (isLogin) return;

        setModalShow({
            type: DialogModalType.Warning,
            content:
                <>
                    <p className="mb-0">Login expirado!</p>
                    <p className="mb-0">Por favor, realize o login novamente</p>
                </>,
            actions: [
                {
                    text: 'Retornar ao portal',
                    onClick: () => {
                        window.location.href = process.env.REACT_APP_Portal_URL ?? "";
                    },
                    variant: 'success',
                },
            ],
        });
    }, [isLogin, setModalShow, user]);

    if (user || isLogin)
        return <>{children}</>;

    return <></>
};