import { useEffect, useState } from "react";

const useNetworkStatus = () => {
  const [networkStatus, setNetworkStatus] = useState<boolean>();

  useEffect(() => {
    window.ononline = (_) => {
      console.log("Conexão restaurada");
      setNetworkStatus(true);
    };

    window.onoffline = (_) => {
      console.log("Sem conexão com a internet");
      setNetworkStatus(false);
    };
  }, [networkStatus]);

  return networkStatus;
};

export default useNetworkStatus;