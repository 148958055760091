import moment from "moment";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import wcmatch from 'wildcard-match';

export function appendClassName(fixed: string, appended?: string) {
  const splittedFixed = fixed.split(' ');
  const appendedDynamic = appended?.split(' ');
  const merged = [...splittedFixed, ...(appendedDynamic ?? [])];
  return merged.join(' ');
}

export function usePathUtil() {
  const { pathname } = useLocation();
  const splittedPath = pathname.split('/');
  splittedPath.splice(splittedPath.length - 1)
  return {
    pathLast: splittedPath[splittedPath.length - 1],
    pathname,
    parentPath: splittedPath.join('/'),
    pathMatches: (path: string) =>
      wcmatch([`**/${path}`, `**/${path}/*`])(pathname),
  }
}

export function useNavigateWithId() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const idParam = searchParams.get('id');
  
  return function (to: string) {
    navigate(to + (idParam ? `?id=${idParam}` : ''));
  };
}

export function dateToString(date: Date) {
  let year = date.getFullYear();
  // Necessário inserir +1 pois Javascript Date traz um mês atrás.
  // TODO considerar usar o Moment, pois o Date do Javascript é
  // conhecido por ser zoado.
  let month = (date.getMonth() + 1).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  let day = date.getDate().toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });

  return `${year}-${month}-${day}`;
}

export function formatDateString(dateString: string) {
  if (!dateString) return '';
  return moment(dateString).format('DD/MM/YYYY');
}

export function today() {
  return moment().format('yyyy-MM-DD');
}

export function stringOrEmpty(value: string | null | undefined) {
  return value || '';
}