import { Filter } from "features/common/components/Filter";
import FormControl from "features/common/components/FormControl";
import { StatusAnaliseCritica } from "features/projetos/models/AnaliseCritica";
import { FC, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { UseFormRegister, UseFormReset } from "react-hook-form/dist/types";
import { AnaliseCriticaRequest } from "./AnaliseCriticaTab";

interface AnaliseCriticaFilterProps {
  isFilterVisible: boolean;
  setIsFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
  register: UseFormRegister<AnaliseCriticaRequest>;
  reset: UseFormReset<AnaliseCriticaRequest>;
}

const AnaliseCriticaFilter: FC<AnaliseCriticaFilterProps> = ({
  isFilterVisible,
  setIsFilterVisible,
  register,
  reset,
}) => {
  useEffect(() => {
    if (!isFilterVisible) {
      reset();
    }
  }, [isFilterVisible, reset]);

  return <Form>
    <Filter.Container
      isVisible={isFilterVisible}
      setIsVisible={setIsFilterVisible}
    >
      <Col>
        <FormControl label="OP" controlId="op">
          <Form.Control {...register("op")}
            type="number"
            placeholder="Pesquise..." />
        </FormControl>
      </Col>

      <Col>
        <FormControl label="Status" controlId="status">
          <Form.Select {...register("status")} defaultValue="">
            <option value="">Todos</option>
            <option value={StatusAnaliseCritica.Aprovado}>Aprovado</option>
            <option value={StatusAnaliseCritica.Reprovado}>Reprovado</option>
          </Form.Select>
        </FormControl>
      </Col>

      <Col>
        <FormControl label="Número TEX" controlId="tex">
          <Form.Control {...register("numeroTex")}
            type="number"
            placeholder="Pesquise..." />
        </FormControl>
      </Col>
    </Filter.Container>
  </Form>
};

export default AnaliseCriticaFilter;