import FormControl from "features/common/components/FormControl";
import FormControlTextArea from "features/common/components/FormControlTextArea";
import yup from "config/yup";
import useRequest, { RequestStatus } from "features/common/hooks/useRequest";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { FC, useEffect } from "react";
import { Button, Col, Form, Offcanvas, OffcanvasProps } from "react-bootstrap";
import closeIcon from "../../../../../../assets/icon-close.png";
import { Shape } from "features/common/types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationResult } from "features/common/models/ValidationResult";
import createAnaliseCritica from "features/projetos/services/analise-critica/createAnaliseCritica";
import { AnaliseCritica } from "features/projetos/models/AnaliseCritica";
import { fetchTexByProjeto } from "features/projetos/services/tex/fetchTexByProjeto";
import { DropdownDto } from "features/common/models/DropdownDto";
import Projeto from "features/projetos/models/Projeto";
import updateAnaliseCritica from "features/projetos/services/analise-critica/updateAnaliseCritica";
import moment from "moment";

export interface EditarAnaliseCriticaOffCanvasProps extends OffcanvasProps {
	analiseCritica?: AnaliseCritica;
	projeto?: Projeto;
	onSuccess?: () => void;
}

export interface AnaliseCriticaForm {
	projetoId: string;
	codigoProjeto: string;
	numeroTex: number;
	ordemProducao: number;
	itemExtrusao: string;
	codigoReferencia: string;
	nrTabelaItens: number;
	nrAssistTecnica: number;
	cliente: string;
	quantidadeOp: number;
	dataProducao: string;
	avaliacaoProducao: string;
	avaliacaoCliente: string;
	conclusaoRodada: string;
	status: number;
}

const analiseCriticaSchema = yup.object<Shape<AnaliseCriticaForm>>({
	projetoId: yup.string().required(),
	codigoProjeto: yup.string().required(),
	numeroTex: yup.number().typeError("É necessário selecionar uma opção").required().label('TEXS'),
	ordemProducao: yup.number().typeError("OP é obrigatório").required(),
	itemExtrusao: yup.string().max(120).required().label('Item de extrusão'),
	codigoReferencia: yup.string().max(120).required().label('Cód Referência'),
	nrTabelaItens: yup.number().typeError("Nr Tabela Itens é obrigatório").required(),
	nrAssistTecnica: yup.number().typeError("Nr Assistência Técnica é obrigatório").required(),
	cliente: yup.string().max(120).required().label('Cliente'),
	quantidadeOp: yup.number().typeError("Quantidade OP é obrigatório").required(),
	dataProducao: yup.string().required().label("Data de Produção"),
	avaliacaoProducao: yup.string().max(500).required().label('Avaliação Produção'),
	avaliacaoCliente: yup.string().max(500).required().label('Avaliação Cliente'),
	conclusaoRodada: yup.string().max(500).required().label('Conclusão da Rodada'),
	status: yup.number().typeError("É necessário selecionar uma opção").required().label("Status"),
}).required();

export const EditarAnaliseCriticaOffCanvas: FC<EditarAnaliseCriticaOffCanvasProps> = ({
	show,
	onHide,
	style,
	projeto,
	onSuccess,
	analiseCritica,
	...props
}) => {
	const {
		progress: { setIsLoading },
		dialog: { toast },
	} = useUIBehaviour();

	const {
		handleSubmit,
		register,
		reset,
		setValue,
		formState: { errors },
	} = useForm<AnaliseCriticaForm>({
		resolver: yupResolver(analiseCriticaSchema),
	});

	const loadTexsRequest = useRequest<string, DropdownDto[]>(fetchTexByProjeto);
	const createAnaliseCriticaRequest =
		useRequest<AnaliseCritica, ValidationResult>(createAnaliseCritica);
	const updateAnaliseCriticaRequest =
		useRequest<AnaliseCritica, ValidationResult>(updateAnaliseCritica);

	useEffect(() => {
		if (show && projeto && projeto.id) {
			loadTexsRequest.execute(projeto.id);
			reset({});
			setValue('codigoProjeto', projeto.codigoProjeto);
			setValue('projetoId', projeto.id);
		}
	}, [show]);

	useEffect(() => {
		setIsLoading(loadTexsRequest.status === RequestStatus.Loading);

		if (loadTexsRequest.status === RequestStatus.Success) {
			if (analiseCritica) {
				reset({
					...analiseCritica,
					dataProducao: moment(analiseCritica?.dataProducao).format('YYYY-MM-DD'),
				});
			}
		}

		if (loadTexsRequest.status === RequestStatus.Failed) {
			toast.error('Algo inesperado aconteceu');
		}
	}, [loadTexsRequest.status]);

	useEffect(() => {
		setIsLoading(createAnaliseCriticaRequest.status === RequestStatus.Loading);

		if (createAnaliseCriticaRequest.status === RequestStatus.Success) {
			toast.success('Salvo com sucesso');
			onHide();
			reset();
			onSuccess && onSuccess();
		}

		if (createAnaliseCriticaRequest.status === RequestStatus.Failed) {
			toast.error(
				createAnaliseCriticaRequest.failure?.notifications?.[0].message ??
				'Algo inesperado aconteceu');
		}
	}, [createAnaliseCriticaRequest.status]);

	useEffect(() => {
		setIsLoading(updateAnaliseCriticaRequest.status === RequestStatus.Loading);

		if (updateAnaliseCriticaRequest.status === RequestStatus.Success) {
			toast.success('Salvo com sucesso');
			onHide();
			reset();
			onSuccess && onSuccess();
		}

		if (updateAnaliseCriticaRequest.status === RequestStatus.Failed) {
			toast.error(
				updateAnaliseCriticaRequest.failure?.notifications?.[0].message ??
				'Algo inesperado aconteceu');
		}
	}, [updateAnaliseCriticaRequest.status]);

	function cancelarInserirAnaliseCritica() {
		onHide();
		reset();
	}

	function saveHandler(data: AnaliseCritica) {
		if (analiseCritica) {
			updateAnaliseCriticaRequest.execute(data);
		} else {
			createAnaliseCriticaRequest.execute(data);
		}
	}

	return (
		<Offcanvas
			style={{ width: 600, height: "100vh" }}
			show={show}
			placement="end"
			onHide={cancelarInserirAnaliseCritica}
			{...props}>
			<Offcanvas.Header>
				<div className="w-100 d-flex justify-content-between border-bottom pb-2">
					<Offcanvas.Title className="">
						{analiseCritica ?
							'Editar Análise Crítica' :
							'Inserir Análise Crítica'}
					</Offcanvas.Title>
					<Button className="cursor-pointer p-0" onClick={onHide} variant="link">
						<img alt="Fechar Modal" src={closeIcon} />
					</Button>
				</div>
			</Offcanvas.Header>
			<Form style={{
				display: 'flex',
				flexDirection: 'column',
				flex: 1,
			}} onSubmit={handleSubmit(saveHandler, (errors) => { console.error(errors) })}>
				<Offcanvas.Body className="overflow-auto" style={{ height: 0 }}>
					<Col style={{ width: 350 }}>
						<FormControl
							label='TEXS'
							error={errors.numeroTex}
							controlId="numeroTex"
							style={{ marginBottom: 20 }}>
							<Form.Select {...register("numeroTex")}>
								{loadTexsRequest.status === RequestStatus.Loading && <option>Carregando</option>}
								{loadTexsRequest.status === RequestStatus.Success && <>
									<option>Selecione uma opção</option>
									{loadTexsRequest.data?.map((tex, key) => <option
										key={key}
										value={tex.id}>{tex.text}</option>)}
								</>}
							</Form.Select>
						</FormControl>
						<FormControl
							label='OP'
							error={errors.ordemProducao}
							controlId="ordemProducao"
							style={{ marginBottom: 20 }}>
							<Form.Control
								{...register("ordemProducao")}
								type="number"
								placeholder="Insira o número" />
						</FormControl>
						<FormControl
							label='Item de extrusão'
							error={errors.itemExtrusao}
							controlId="itemExtrusao"
							maxCharacters={120}
							style={{ marginBottom: 20 }}>
							<Form.Control
								{...register("itemExtrusao")}
								type="text"
								placeholder="Insira o texto" />
						</FormControl>
						<FormControl
							label='Cód Referência'
							error={errors.codigoReferencia}
							controlId="codigoReferencia"
							maxCharacters={120}
							style={{ marginBottom: 20 }}>
							<Form.Control
								{...register("codigoReferencia")}
								type="text"
								placeholder="Insira o texto" />
						</FormControl>
						<FormControl
							label='Nr Tabela Itens'
							error={errors.nrTabelaItens}
							controlId="nrTabelaItens"
							style={{ marginBottom: 20 }}>
							<Form.Control
								{...register("nrTabelaItens")}
								type="number"
								placeholder="Insira o número" />
						</FormControl>
						<FormControl
							label='Nr Assistência Técnica'
							error={errors.nrAssistTecnica}
							controlId="nrAssistTecnica"
							style={{ marginBottom: 20 }}>
							<Form.Control
								{...register("nrAssistTecnica")}
								type="number"
								placeholder="Insira o número" />
						</FormControl>
						<FormControl
							label='Cliente'
							error={errors.cliente}
							controlId="cliente"
							maxCharacters={120}
							style={{ marginBottom: 20 }}>
							<Form.Control
								{...register("cliente")}
								type="text"
								placeholder="Insira o texto" />
						</FormControl>
						<FormControl
							label='Quantidade OP'
							error={errors.quantidadeOp}
							controlId="quantidadeOp"
							style={{ marginBottom: 20 }}>
							<Form.Control
								{...register("quantidadeOp")}
								type="number"
								placeholder="Insira o número" />
						</FormControl>
						<FormControl
							label='Data de produção'
							error={errors.dataProducao}
							controlId="dataProducao"
							style={{ marginBottom: 20 }}>
							<Form.Control
								{...register("dataProducao")}
								type="date" />
						</FormControl>
						<FormControl
							label='Avaliação Produção'
							error={errors.avaliacaoProducao}
							controlId="avaliacaoProducao"
							maxCharacters={500}
							style={{ marginBottom: 20 }}>
							<FormControlTextArea
								{...register("avaliacaoProducao")}
								height={55}
								placeholder="Insira o texto" />
						</FormControl>
						<FormControl
							label='Avaliação Cliente'
							error={errors.avaliacaoCliente}
							controlId="avaliacaoCliente"
							maxCharacters={500}
							style={{ marginBottom: 20 }}>
							<FormControlTextArea
								{...register("avaliacaoCliente")}
								height={55}
								placeholder="Insira o texto" />
						</FormControl>
						<FormControl
							label='Conclusão da Rodada'
							error={errors.conclusaoRodada}
							controlId="conclusaoRodada"
							maxCharacters={500}
							style={{ marginBottom: 20 }}>
							<FormControlTextArea
								{...register("conclusaoRodada")}
								height={55}
								placeholder="Insira o texto" />
						</FormControl>
						<FormControl
							label='Status'
							error={errors.status}
							controlId="status"
							style={{ marginBottom: 20 }}>
							<Form.Select {...register("status")}>
								<option>Selecione uma opção</option>
								<option value={0}>Aprovado</option>
								<option value={1}>Reprovado</option>
							</Form.Select>
						</FormControl>
					</Col>
				</Offcanvas.Body>
				<div className="d-flex p-4">
					<Button
						style={{ width: 146 }}
						size="lg"
						type='button'
						variant="outline-success"
						onClick={() => cancelarInserirAnaliseCritica()}>Voltar</Button>
					<div style={{ flex: 1 }}></div>
					<Button
						style={{ width: 146 }}
						size="lg"
						type="submit"
						variant="success">Salvar</Button>
				</div>
			</Form>
		</Offcanvas>
	);
};