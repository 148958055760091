import useRequest, { RequestStatus } from "features/common/hooks/useRequest";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { fetchTex } from "features/projetos/services/tex/fetchTex";
import { createTex } from "features/projetos/services/tex/createTex";
import { useCallback, useEffect } from "react";

export interface CreateTexResponse { 
    id: string;
    numero: number;
    status?: number;
    definicao?: string;
};

export interface FetchTexResponse { 
    id: string;
    numero: number;
    status: number;
    definicao: string;
};

export type TexResponse = CreateTexResponse | FetchTexResponse;

export const useFetchTex = () => {
    const {
        progress: { setIsLoading },
        dialog: { toast },
    } = useUIBehaviour();

    const handleRequest = useCallback((texEditId : string | undefined) => {
        return texEditId ? fetchTex(texEditId) : createTex();
    }, []);

    const { execute, data, status, failure } = useRequest<string | undefined, TexResponse>(handleRequest);

    useEffect(() => {
        setIsLoading(status === RequestStatus.Loading);

        if (status === RequestStatus.Failed) {
            toast.error(failure?.notifications?.[0].message ?? "Algo deu errado");
        }
    }, [failure?.notifications, setIsLoading, status, toast]);

    return { tex: data, fetchTex: execute };
};