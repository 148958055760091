import { appendClassName } from "features/common/utils";
import React, { useRef } from "react";
import { Container as BootstraoContainer, Row, Button } from "react-bootstrap";
import { XCircle } from "react-bootstrap-icons";
import './style.scss';

type ContainerProps = {
  children: React.ReactElement | React.ReactElement[];
  setIsVisible: (isVisible: boolean) => void;
  isVisible: boolean;
  style?: React.CSSProperties;
  className?: string;
};

export const Container = ({
  children,
  isVisible,
  setIsVisible,
  style,
  className,
}: ContainerProps) => {
  const innerRef = useRef<HTMLDivElement>(null);

  return (
    <BootstraoContainer
      fluid
      style={{
        ...style,
        maxHeight: isVisible
          ? `calc(${innerRef?.current?.clientHeight}px + 10px)`
          : "0px",
        overflow: "hidden",
        transition: "all 0.5s",
        padding: "0",
      }}
      className={appendClassName("FilterContainer", className)}
    >
      <div
        ref={innerRef}
        style={{
          border: "1px solid rgba(178, 178, 178, 0.3)",
          height: "100%",
          width: "100%",
          borderRadius: "0.5rem",
          position: "relative",
          padding: "2rem 1.8rem",
        }}
      >
        <Button
          type="button"
          variant="link"
          onClick={() => setIsVisible(false)}
          style={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            height: "1.5rem",
            width: "1.5rem",
            color: "rgba(178, 178, 178, 0.8)",
            padding: "0",
            display: "flex",
          }}
        >
          <XCircle
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Button>
        <Row>{children}</Row>
      </div>
    </BootstraoContainer>
  );
};
