import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "features/common/redux/store";
import axios from "axios";
import { UserState } from "./pages/types";

const initialState: UserState = {
  isValid: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userloggedIn: (_, action) => {
      return {
        ...action.payload,
      };
    },
    userLoggedOut: () => initialState,
  },
});

export const { userloggedIn, userLoggedOut } = authSlice.actions;

export const selectUserToken = (state: RootState) => state.auth.token;

export const selectUserDivisions = (state: RootState) =>
  state.auth.data?.divisions?.map((item) => {
    return { id: item?.id, text: item?.name };
  });

export const selectCurrentUserDivision = (state: RootState) =>
  state.auth.data?.divisions?.filter(
    (item) => item.id === state.auth?.data?.defaultDivisionId
  )[0] ?? state.auth.data?.divisions?.[0];

export const selectUserDepartments = (state: RootState) =>
  state.auth.data?.departments || [];

export const selectAuth = (state: RootState) => state.auth;

export const selectUserId = (state: RootState) => state.auth.userId;

export const selectUserDivisionId = (state: RootState) =>
  state.auth.data?.defaultDivisionId;

export const selectModules = (state: RootState) => state.auth.data?.modules;

export default authSlice.reducer;
