import { Spinner } from "react-bootstrap";

const FullscreenProgressIndicator = ({ text }: { text?: string }) => {
  return <div className="FullscreenProgressIndicator" style={{
    backgroundColor: '#FFFFFF77',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }}>
    <Spinner animation="border" />
    {text && <h5 style={{ marginTop: 15 }}>{text}</h5>}
  </div>
}

export default FullscreenProgressIndicator;