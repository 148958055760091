import { useEffect, useState } from "react";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import Projeto from "features/projetos/models/Projeto";
import { ValidationResult } from "features/common/models/ValidationResult";

interface UseChatProps {
    token: string;
    projetoId: string;
    projeto: Projeto | undefined;
    onStart: () => void;
    onConnectionError: () => void;
    onProjetoFetched: (projeto: Projeto) => void;
    onProjetoError: (statusCode: number, validationResult: ValidationResult) => void;
}

export const useProjetoConcorrencia = ({
    token,
    projetoId,
    projeto,
    onStart,
    onConnectionError,
    onProjetoFetched,
    onProjetoError,
}: UseChatProps) => {
    const [connection, setConnection] = useState<HubConnection | null>(null);

    useEffect(() => {
        if (projeto) return;
        if (!projetoId) return;

        const connection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_URL + "/api/projetoconcorrencia" ?? "")
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Error)
            .build();

        setConnection(connection);
    }, [projeto, projetoId]);

    useEffect(() => {
        if (projeto) return;
        if (!projetoId) return;
        if (!connection) return;

        connection.start()
            .then(() => {
                onStart();
                connection?.invoke("LockProjeto", token, projetoId);
            })
            .catch((ex) => {
                onConnectionError();
            });

        connection.on("ProjetoFetched", (projeto: Projeto) => {
            onProjetoFetched(projeto);
        });

        connection.on("ProjetoError", (statusCode: number, validationResult: ValidationResult) => {
            onProjetoError(statusCode, validationResult);
        });

        return () => {
            connection.off("ProjetoFetched");
            connection.off("ProjetoError");
        };
    }, [connection, token, projetoId, onProjetoFetched, onProjetoError, onStart, onConnectionError, projeto]);
};
