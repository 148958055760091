import DropdownActions from "features/common/components/DropdownActions";
import List from "features/common/components/List";
import ListTile from "features/common/components/ListTile";
import ListTileColumn from "features/common/components/ListTileColumn";
import useRequest, { RequestStatus } from "features/common/hooks/useRequest";
import { PagedResult } from "features/common/models/PagedResult";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { useCallback, useEffect, useState } from "react";
import { Badge, Button, Form } from "react-bootstrap"
import { useSearchParams } from "react-router-dom";
import EmptyListImage from 'assets/empty-list.png';
import ExcelButtonImage from 'assets/excel-button.png';
import { ApplicationPagination } from "features/common/components/ApplicationPagination";
import { formatDateString } from "features/common/utils";
import AnaliseCriticaFilter from "./AnaliseCriticaFilter";
import PaginationBase from "features/common/models/PaginationBase";
import { fetchAnalisesCriticas } from "features/projetos/services/analise-critica/fetchAnalisesCriticas";
import { AnaliseCritica, mapBgStatusAnaliseCriticaToString, mapStatusAnaliseCriticaToString } from "features/projetos/models/AnaliseCritica";
import { useForm } from "react-hook-form";
import { useDownloadFile } from "features/common/hooks/useExportExcel";
import { VisualizarAnaliseCriticaOffCanvas } from "./VisualizarAnaliseCriticaOffCanvas";
import { EditarAnaliseCriticaOffCanvas } from "./EditarAnaliseCriticaOffCanvas";
import { useProjeto } from "features/projetos/providers/ProjetoProvider";
import FormControl from "features/common/components/FormControl";
import { fetchUltimaDataIntegracao } from "features/projetos/services/analise-critica/fetchUltimaDataIntegracao";
import { ValidationResult } from "features/common/models/ValidationResult";
import { deleteAnaliseCritica } from "features/projetos/services/analise-critica/deleteAnaliseCritica";
import { DropdownAction } from "features/common/components/DropdownActions/DropdownActions";
import { DialogModalType } from "features/common/components/DialogModal/DialogModal";

export interface AnaliseCriticaRequest extends PaginationBase {
  projetoId: string;
  op?: number;
  status?: number;
  numeroTex?: number;
}

const AnaliseCriticaTab = () => {
  const {
    progress: { setIsLoading },
    dialog: { setModalShow, toast },
  } = useUIBehaviour();

  const [params] = useSearchParams();
  const { projeto } = useProjeto();
  const { getValues, watch, register, reset } = useForm<AnaliseCriticaRequest>();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [mostrarDataHoraIntegracao, setMostrarDataHoraIntegracao] = useState(false);
  const [showEditarOffCanvas, setShowEditarOffCanvas] = useState(false);
  const [selectedAnaliseCritica, setSelectedAnaliseCritica] = useState<AnaliseCritica>();
  const [visualizarAnaliseId, setVisualizarAnaliseId] = useState<string | undefined>(undefined);
  const [podeAdicionarAnaliseCritica, setPodeAdicionarAnaliseCritica] = useState(false);
  const { execute, status, data: analises, failure } = useRequest<AnaliseCriticaRequest, PagedResult<AnaliseCritica>>(fetchAnalisesCriticas);
  const ultimaDataIntegracaoRequest = useRequest<null, string>(fetchUltimaDataIntegracao);
  const deleteAnaliseCriticaRequest = useRequest<string, ValidationResult>(deleteAnaliseCritica);

  const { download } = useDownloadFile();
  const projetoId = params.get("id") ?? "";

  useEffect(() => {
    if (projeto && projeto.divisao !== 'MG2') {
      setPodeAdicionarAnaliseCritica(true);
    }
  }, [projeto]);

  useEffect(() => {
    setIsLoading(status === RequestStatus.Loading);

    if (status === RequestStatus.Failed) {
      toast.error(failure?.notifications?.[0].message ?? 'Algo inesperado aconteceu');
    }
  }, [failure?.notifications, setIsLoading, status, toast]);

  const filterCallback = useCallback(() => {
    const { op, status, numeroTex } = getValues();

    execute({
      projetoId,
      page: parseInt(params.get("page") ?? '1'),
      take: 4,
      op,
      status,
      numeroTex
    });
  }, [execute, getValues, params, projetoId]);

  useEffect(() => {
    if (projeto && projeto.divisao === 'MG2') {
      ultimaDataIntegracaoRequest.execute(null);
      setMostrarDataHoraIntegracao(true);
    }
  }, [projeto]);

  useEffect(() => {
    setIsLoading(ultimaDataIntegracaoRequest.status === RequestStatus.Loading);

    if (ultimaDataIntegracaoRequest.status === RequestStatus.Failed) {
      toast.error('Algo inesperado aconteceu');
    }
  }, [ultimaDataIntegracaoRequest.status]);

  useEffect(() => {
    setIsLoading(ultimaDataIntegracaoRequest.status === RequestStatus.Loading);

    if (ultimaDataIntegracaoRequest.status === RequestStatus.Failed) {
      toast.error('Algo inesperado aconteceu');
    }
  }, [ultimaDataIntegracaoRequest.status]);

  useEffect(() => {
    setIsLoading(deleteAnaliseCriticaRequest.status === RequestStatus.Loading);

    if (deleteAnaliseCriticaRequest.status === RequestStatus.Success) {
      filterCallback();
      toast.success('Deletado com sucesso');
    }

    if (deleteAnaliseCriticaRequest.status === RequestStatus.Failed) {
      toast.error('Algo inesperado aconteceu');
    }
  }, [deleteAnaliseCriticaRequest.status]);

  useEffect(() => {
    filterCallback();
  }, [filterCallback]);

  useEffect(() => {
    const subscription = watch(() => {
      filterCallback();
    });
    return () => subscription.unsubscribe();
  }, [filterCallback, watch]);

  function populateDropdownActions(analise: AnaliseCritica) {
    let dropdownActions: DropdownAction[] = [{
      text: "Visualizar",
      onClick: () => { setVisualizarAnaliseId(analise.id) },
    }];

    if (podeAdicionarAnaliseCritica) {
      dropdownActions = [
        ...dropdownActions,
        {
          text: "Editar",
          onClick: () => {
            setShowEditarOffCanvas(true);
            setSelectedAnaliseCritica(analise);
          },
        },
        {
          text: "Deletar",
          onClick: () => analise.id && deleteAnaliseCriticaPrompt(analise.id),
        },
      ]
    }

    return dropdownActions;
  }

  function deleteAnaliseCriticaPrompt(id: string) {
    setModalShow({
      type: DialogModalType.Warning,
      footer: {
        className: "justify-content-between"
      },
      content: <span>Deseja excluir esta Análise Crítica? Após a exclusão, não será possível recuperá-la.</span>,
      actions: [
        {
          text: 'Não',
          onClick: () => setModalShow(),
          variant: 'outline-success',
        },
        {
          text: 'Sim',
          onClick: async () => {
            deleteAnaliseCriticaRequest.execute(id);
            setModalShow();
          },
          variant: 'success',
        },
      ],
    });
  }

  let content = <></>;

  if (analises && analises?.records && analises.records.length === 0) {
    content = (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
      }}>
        <img src={EmptyListImage} alt="Nenhum dado encontrado" />
      </div>
    )
  }
  else if (analises && analises?.records && analises.records.length) {
    content = (
      <List className="AnaliseCriticaList" gap={15}>
        {!analises?.records.length && (
          <div className="d-flex justify-content-center">
            <img src={EmptyListImage} alt="Nenhuma informação encontrada" />
          </div>
        )}

        {analises.records.map((analise, key) => (
          <ListTile
            key={key}
            end={
              <DropdownActions
                actions={populateDropdownActions(analise)} />
            }
          >
            <ListTileColumn label="Projeto" flex={1}>
              <span>{analise.codigoProjeto}</span>
            </ListTileColumn>

            <ListTileColumn label="Número TEX" flex={1}>
              <span>{analise.numeroTex}</span>
            </ListTileColumn>

            <ListTileColumn label="Cód. Referência" flex={1}>
              <span>{analise.codigoReferencia}</span>
            </ListTileColumn>

            <ListTileColumn label="Item Extrusão" flex={1}>
              <span>{analise.itemExtrusao}</span>
            </ListTileColumn>

            <ListTileColumn label="NR Tab Itens" flex={1}>
              <span>{analise.nrTabelaItens}</span>
            </ListTileColumn>

            <ListTileColumn label="OP" flex={1}>
              <span>{analise.ordemProducao}</span>
            </ListTileColumn>

            <ListTileColumn label="Quantidade OP" flex={1}>
              <span>{analise.quantidadeOp}</span>
            </ListTileColumn>

            <ListTileColumn label="Data Produção" flex={1}>
              <span>{formatDateString(analise.dataProducao)}</span>
            </ListTileColumn>

            <ListTileColumn label="Status" flex={1}>
              <Badge bg={mapBgStatusAnaliseCriticaToString.get(analise.status)}>
                {mapStatusAnaliseCriticaToString.get(analise.status)}
              </Badge>
            </ListTileColumn>
          </ListTile>
        ))}

      </List>
    );
  }

  return (
    <>
      <div className="AnaliseCriticaTab">
        <div className="d-flex">
          <h4>Análise Crítica</h4>

          <div style={{ width: 20 }}></div>

          {podeAdicionarAnaliseCritica && (
            <Button
              style={{ height: 'max-content' }}
              type='button'
              onClick={() => {
                setShowEditarOffCanvas(true);
              }}>
              Adicionar
            </Button>
          )}

          <div style={{ flex: 1 }}></div>

          <Button
            style={{ height: 'max-content' }}
            type='button'
            onClick={() => setIsFilterVisible((isVisible) => !isVisible)}>Filtrar</Button>
        </div>

        <AnaliseCriticaFilter
          isFilterVisible={isFilterVisible}
          setIsFilterVisible={setIsFilterVisible}
          register={register}
          reset={reset}
        />

        {mostrarDataHoraIntegracao && ultimaDataIntegracaoRequest.data &&
          (
            <FormControl
              label='Última data/hora da integração'
              controlId="ordemProducao"
              style={{ width: 200, marginBottom: 20 }}>
              <Form.Control
                value={ultimaDataIntegracaoRequest.data}
                type="text"
                disabled />
            </FormControl>
          )
        }

        <div className="d-flex">
          <h5>Listagem</h5>
          <div style={{ flex: 1 }}></div>
          {analises && analises?.records && analises.records.length > 0 && (
            <Button variant="link" onClick={() => {
              download("/AnaliseCritica/ExportExcel", "Análises críticas.xlsx", { ...getValues(), projetoId });
            }}>
              <img width={24} height={24} alt="Exportar para Excel" src={ExcelButtonImage} />
            </Button>
          )}
        </div>

        <div style={{ height: 10 }}></div>

        {content}

        <ApplicationPagination
          style={{ float: 'right', marginTop: 20 }}
          itensPerPage={4}
          totalItems={analises?.recordsTotal}
          itensQuantity={analises?.records.length ?? 0}
        />
      </div>

      {visualizarAnaliseId && (<VisualizarAnaliseCriticaOffCanvas setIsVisible={setVisualizarAnaliseId} analiseId={visualizarAnaliseId} />)}
      {podeAdicionarAnaliseCritica && <EditarAnaliseCriticaOffCanvas
        analiseCritica={selectedAnaliseCritica}
        projeto={projeto}
        show={showEditarOffCanvas}
        onHide={() => {
          setSelectedAnaliseCritica(undefined);
          setShowEditarOffCanvas(false);
        }}
        onSuccess={filterCallback} />}
    </>
  );
}

export default AnaliseCriticaTab;