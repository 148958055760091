import { appendClassName } from "features/common/utils";
import { useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./style.scss";

export const LargeText = ({ label, children }: { label: string; children: string | undefined }) => {
    const [isMore, setIsMore] = useState(false);
    const textContainer = useRef<any>(null);

    const isVisible = textContainer.current && textContainer.current.scrollHeight > textContainer.current.clientHeight;

    return (
        <div className="position-relative pb-2">
            <Form.Label className="mb-0 text-val-gray-800">{label}</Form.Label>
            <p
                ref={textContainer}
                className={appendClassName("lh-base fs-7", isMore ? "" : "clamped-text")}
            >
                {children}
            </p>

            {isVisible && (
                <Button
                    variant="link"
                    className="position-absolute bottom-0 end-0 fs-7 text-black-50"
                    onClick={() => setIsMore((isMore) => !isMore)}
                >
                    {isMore ? "Ver Menos" : "Ver Mais"}
                </Button>
            )}
        </div>
    );
};