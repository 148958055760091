import axios from 'axios';

export interface AxiosConfig {
  token: string;
}

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`;
axios.interceptors.request.use(
  config => {
    // (configurações de interceptação)
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

export function axiosConfig(config?: AxiosConfig) {
  if (config) {
    axios.defaults.headers.common['Token'] = config.token;
  }
}