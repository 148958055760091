import { unlockProjeto } from "features/projetos/services/projeto/unlockProjeto";
import { useEffect, useState } from "react";
import { useLocation, Location } from "react-router-dom";
import useRequest from "./useRequest";

/*
 * Hook utilizado para verificar quaisquer alterações de rota.
 *
 * Neste momento ele verifica a saída da rota de edição de projeto
 * para desbloquea-lo.
 * 
 * Adicionar a ação aos botões "voltar" individualmente é complexo,
 * uma vez que se algum programador esquecer de coloca-lo, gerará um erro 
 * que bloqueará o projeto até alguém desbloqua-lo manualmente no banco de dados.
*/
export const useLocationChange = () => {
    const location = useLocation();
    const [previousLocation, setPreviousLocation] = useState<Location | undefined>(undefined);

    const { execute } = useRequest(unlockProjeto);

    useEffect(() => {
        if (!location.pathname.includes("edit")) {
            const params = new URLSearchParams(previousLocation?.search);
            const projetoId = params.get('id');

            if (projetoId) execute(projetoId);
        }

        setPreviousLocation(location);

    }, [execute, location, previousLocation]);
};