import axios from "axios";
import { IResponse } from "features/common/hooks/useRequest";
import { PagedResult } from "features/common/models/PagedResult";
import { AnaliseCriticaRequest } from "features/projetos/pages/ProjetoEditPage/tabs/AnaliseCriticaTab/AnaliseCriticaTab";
import { AnaliseCritica } from "features/projetos/models/AnaliseCritica";

export async function fetchAnalisesCriticas(data: AnaliseCriticaRequest): Promise<IResponse<PagedResult<AnaliseCritica>>> {
    data.page = data.page - 1;

    const queryString = Object.keys(data).map(key => {
        let value = data[key as keyof AnaliseCriticaRequest];

        if (!value) return null;

        return encodeURIComponent(key) + '=' + encodeURIComponent(value)
    }).filter((item) => item !== null).join('&');

    return await axios.get(`/AnaliseCritica/List?${queryString}`);
}
