import './App.scss';
import { Route, Routes, } from 'react-router-dom';
import MainNav from 'features/common/components/MainNav';
import ProjetosListPage from 'features/projetos/pages/ProjetosListPage';
import ProjetoEditPage from 'features/projetos/pages/ProjetoEditPage';
import { Login } from 'features/auth/pages/Login';
import { Container } from 'react-bootstrap';
import DadosDoProjetoTab from 'features/projetos/pages/ProjetoEditPage/tabs/DadosDoProjetoTab';
import TexDoProjetoPage from 'features/projetos/pages/ProjetoEditPage/TexDoProjetoPage';
import EntradasTab from 'features/projetos/pages/ProjetoEditPage/tabs/EntradasTab';
import AnaliseCriticaTab from 'features/projetos/pages/ProjetoEditPage/tabs/AnaliseCriticaTab';
import ValidacoesTab from 'features/projetos/pages/ProjetoEditPage/tabs/ValidacoesTab';
import Logout from 'features/auth/pages/Logout';
import { useAppSelector } from './redux/hooks';
import { selectUserToken } from 'features/auth/authSlice';
import { axiosConfig } from 'config/axios';
import { useLocationChange } from './hooks/useLocationChange';
import { Auth } from 'features/auth/Auth';

function App() {
  const token = useAppSelector(selectUserToken);
  axiosConfig(token ? { token } : undefined);

  useLocationChange();

  return (
    <div className="App">
      <MainNav />

      <div className="AppBody">
        {/* <MainMenu /> */}
        <Container className="pt-4 pb-3">
          <Auth>
            <Routes>
              <Route path="/" element={<ProjetosListPage />} />
              <Route path="edit" element={<ProjetoEditPage />}>
                <Route path="dados-do-projeto">
                  <Route path="" element={<DadosDoProjetoTab />} />
                  <Route path="tex" element={<TexDoProjetoPage />} />
                </Route>
                <Route path="entradas" element={<EntradasTab />} />
                <Route path="analise-critica" element={<AnaliseCriticaTab />} />
                <Route path="validacoes" element={<ValidacoesTab />} />
              </Route>
              {/* <Route path="edit" element={<></>} /> */}
              <Route path="/Login" element={<Login />} />
              <Route path="/Logout" element={<Logout />} />
            </Routes>
          </Auth>
        </Container>
      </div>
    </div>
  );
}

export default App;
