import { Badge, Button, Col, Form, Row } from "react-bootstrap";
import { Shape } from "features/common/types";
import { useEffect, useState } from "react";
import FormControl from "features/common/components/FormControl";
import FormControlTextArea from "features/common/components/FormControlTextArea";
import useRequest, { RequestStatus } from "features/common/hooks/useRequest";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Entradas from "features/projetos/models/Entradas";
import { ValidationResult } from "features/common/models/ValidationResult";
import { fetchEntradas } from "features/projetos/services/entradas/fetchEntradas";
import updateEntradas from "features/projetos/services/entradas/updateEntradas";
import { DialogModalType } from "features/common/components/DialogModal/DialogModal";
import { useProjeto } from "features/projetos/providers/ProjetoProvider";
import yup from "config/yup";
import { findCodigoProjeto } from "features/projetos/services/projeto/findCodigoProjeto";
import { RequisitosOffCanvas } from "../Requisitos/RequisitosOffCanvas";
import { stringOrEmpty } from "features/common/utils";

export interface EntradasForm {
  codCliente: string;
  benchmark: string;
  caracterizacaoNR: string;
  mercPotencial: string;
  espessura: string;
  dataSolicitacao: string;
  responsavel: string;
  normas: string;
  aplicacaoFinal: string;
  avaliarRisco: string;
  documentoMP: string;
  amostra: number;
  propBandeira: string;
  rangePropBand1: string;
  rangePropBand2: string;
  rangePropBand3: string;
  aparenciaFilme: string;
  outrasCaracteristicas: string;
  dadosEntrada: string;
  dataPrevistaFinalizacao: string;
  faseProjeto: string;
  usuarioVerificacao: string;
  dataVerificacao: string;
  observacoes: string;
}

export const entradasSchema = yup.object<Shape<EntradasForm>>({
  codCliente: yup.string().max(120).required().label("Cliente"),
  benchmark: yup.string().max(120).required().label("Benchmark"),
  caracterizacaoNR: yup.string().max(120).required().label("Caracterização nº"),
  mercPotencial: yup.string().max(120).required().label("Mercado Potencial"),
  espessura: yup.string().max(120).required().label("Espessura / Gramatura"),
  dataSolicitacao: yup.string().required().label("Data da Solicitação"),
  responsavel: yup.string().max(120).required().label("Responsável Pela Solicitação"),
  normas: yup.string().max(120).required().label("Normas Legislação"),
  aplicacaoFinal: yup.string().max(120).required().label("Aplicação Final"),
  avaliarRisco: yup.string().max(120).required().label("Avaliar Risco Projeto"),
  documentoMP: yup.string().max(120).required().label("Documento MP Conforme"),
  amostra: yup.number().typeError("É necessário selecionar uma opção").required().label("Disponibilizado Amostra?"),
  propBandeira: yup.string().max(120).required().label("Prop. Bandeira"),
  rangePropBand1: yup.string().max(120).required().label("Range Prop. Band.1"),
  rangePropBand2: yup.string().max(120).required().label("Range Prop. Band.2"),
  rangePropBand3: yup.string().max(120).required().label("Range Prop. Band.3"),
  aparenciaFilme: yup.string().max(120).required().label("Aparência do Filme"),
  outrasCaracteristicas: yup.string().max(3000).required().label("Outras Características"),
  dadosEntrada: yup.string().max(500).required().label("Dados de Entrada"),
  dataPrevistaFinalizacao: yup.string().max(120).required().label("Data Prevista Para Finalização"),
  faseProjeto: yup.string().max(120).required().label("Fase do Projeto"),
  usuarioVerificacao: yup.string().max(120).required().label("Responsável Pela Verificação"),
  dataVerificacao: yup.string().required().label("Data de Verificação"),
  observacoes: yup.string().max(1000).label("Observações"),
}).required();

const EntradasTab = () => {
  const navigate = useNavigate();
  const [idParam, setIdParam] = useState<string | null>();
  const [loadedData, setLoadedData] = useState<Entradas>();
  const findCodigoProjetoRequest = useRequest<string, boolean>(
    data => findCodigoProjeto(data));
  const {
    progress: { setIsLoading },
    dialog: { setModalShow, toast },
  } = useUIBehaviour();
  const [searchParams] = useSearchParams();
  const [isRequisitosVisible, setIsRequisitosVisible] = useState(false);
  const { projeto, setTabIsDirty } = useProjeto();

  const {
    handleSubmit,
    register,
    reset,
    formState: {
      errors,
      isDirty,
    }
  } = useForm<EntradasForm>({
    resolver: yupResolver(entradasSchema),
  });

  const loadRequest =
    useRequest<string, Entradas>(data => fetchEntradas(data));
  const updateRequest =
    useRequest<Entradas, ValidationResult>(data => updateEntradas(data));

  useEffect(() => {
    setIdParam(searchParams.get('id'));
  }, [searchParams]);

  useEffect(() => {
    if (idParam) {
      loadRequest.execute(idParam);
    }
  }, [idParam]);

  useEffect(() => {
    reset(loadedData);
  }, [loadedData]);

  useEffect(() => {
    setIsLoading(loadRequest.status === RequestStatus.Loading ||
      updateRequest.status === RequestStatus.Loading);
  }, [
    loadRequest.status,
    updateRequest.status,
  ])

  useEffect(() => {
    if (loadRequest.status === RequestStatus.Success) {
      setLoadedData(loadRequest.data);
    }

    if (loadRequest.status === RequestStatus.Failed) {
      toast.error('Algo inesperado aconteceu');
    }
  }, [loadRequest.status]);

  useEffect(() => {
    if (updateRequest.status === RequestStatus.Success) {
      toast.success('Projeto salvo');
    }

    if (updateRequest.status === RequestStatus.Success) {
      if (idParam) {
        loadRequest.execute(idParam);
      }
    }

    if (updateRequest.status === RequestStatus.Failed) {
      const notifications = updateRequest.failure?.notifications;
      if (notifications && notifications.length > 0) {
        toast.error(notifications[0].message);
      } else {
        toast.error('Algo inesperado aconteceu');
      }
    }
  }, [updateRequest.status]);

  useEffect(() => {
    setTabIsDirty(isDirty);
  }, [isDirty]);

  function saveHandler(form: Entradas) {
    updateRequest.execute({
      ...form,
      projetoId: stringOrEmpty(idParam),
    });
  }

  return (
    <div className="EntradasTab">
      {isRequisitosVisible && <RequisitosOffCanvas
        isVisible={isRequisitosVisible}
        setIsVisible={setIsRequisitosVisible}
        destination={''}
        onHide={() => isDirty && handleSubmit(saveHandler)()}>
        <FormControl
          label='Observações'
          error={errors.observacoes}
          controlId="observacoes"
          maxCharacters={1000}
          style={{ marginBottom: 20 }}>
          <FormControlTextArea
            {...register("observacoes")}
            height={55}
            placeholder="Insira o texto" />
        </FormControl>
      </RequisitosOffCanvas>}

      <Form onSubmit={handleSubmit(saveHandler)}>
        <div style={{ display: 'flex' }}>
          <h4>Dados de Entrada</h4>
          <div style={{ flex: 1 }}></div>
          <Button
            style={{ height: 'max-content' }}
            onClick={() => setIsRequisitosVisible(true)}>Requisitos</Button>
        </div>

        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <h6 style={{ marginTop: 8 }}>Código do Projeto</h6>
            <Badge
              className="codigo-projeto lg"
              style={{ marginLeft: 15 }}>{projeto?.codigoProjeto}</Badge>
          </div>
        </div>

        <div style={{ height: 20 }}></div>

        <div style={{
          overflow: 'hidden',
          overflowY: 'auto',
          height: 310,
        }}>
          <Row>
            <Col>
              <FormControl
                label='Cliente'
                error={errors.codCliente}
                controlId="codigoCliente"
                maxCharacters={120}
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("codCliente")}
                  type="text"
                  placeholder="Insira o código do cliente" />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                label='Responsável Pela Solicitação'
                error={errors.responsavel}
                controlId="responsavel"
                maxCharacters={120}
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("responsavel")}
                  type="text"
                  placeholder="Insira o responsável" />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                label='Data da Solicitação'
                error={errors.dataSolicitacao}
                controlId="dataSolicitacao"
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("dataSolicitacao")}
                  type="date" />
              </FormControl>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormControl
                label='Dados de Entrada'
                error={errors.dadosEntrada}
                controlId="dadosEntrada"
                maxCharacters={500}
                style={{ marginBottom: 20 }}>
                <FormControlTextArea
                  {...register("dadosEntrada")}
                  height={55}
                  placeholder="Insira os dados" />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                label='Benchmark'
                error={errors.benchmark}
                controlId="benchmark"
                maxCharacters={120}
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("benchmark")}
                  type="text"
                  placeholder="Insira o benchmark" />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                label='Disponibilizado Amostra?'
                error={errors.amostra}
                controlId="status"
                style={{ marginBottom: 20 }}>
                <Form.Select {...register("amostra")}>
                  <option>Selecione uma opção</option>
                  <option value={0}>Não</option>
                  <option value={1}>Sim</option>
                </Form.Select>
              </FormControl>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormControl
                label='Caracterização nº'
                error={errors.caracterizacaoNR}
                controlId="caracterizacaoNR"
                maxCharacters={120}
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("caracterizacaoNR")}
                  type="text"
                  placeholder="Insira a caracterização" />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                label='Mercado Potencial'
                error={errors.mercPotencial}
                controlId="mercPotencial"
                maxCharacters={120}
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("mercPotencial")}
                  type="text"
                  placeholder="Insira qual é o potencial" />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                label='Avaliar Risco Projeto'
                error={errors.avaliarRisco}
                controlId="avaliarRisco"
                maxCharacters={120}
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("avaliarRisco")}
                  type="text"
                  placeholder="Insira o texto"
                />
              </FormControl>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormControl
                label='Normas Legislação'
                error={errors.normas}
                controlId="normas"
                maxCharacters={120}
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("normas")}
                  type="text"
                  placeholder="Insira as normas" />
              </FormControl>
            </Col>

            <Col>
              <FormControl
                label='Aplicação Final'
                error={errors.aplicacaoFinal}
                controlId="aplicacaoFinal"
                maxCharacters={120}
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("aplicacaoFinal")}
                  type="text"
                  placeholder="Insira a aplicação final" />
              </FormControl>
            </Col>

            <Col>
              <FormControl
                label='Documento MP Conforme?'
                error={errors.documentoMP}
                controlId="documentoMP"
                maxCharacters={120}
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("documentoMP")}
                  type="text"
                  placeholder="Insira o texto" />
              </FormControl>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormControl
                label='Aparência do Filme'
                error={errors.aparenciaFilme}
                controlId="aparenciaFilme"
                maxCharacters={120}
                style={{ marginBottom: 35 }}>
                <Form.Control
                  {...register("aparenciaFilme")}
                  type="text"
                  placeholder="Insira o texto" />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                label='Espessura / Gramatura'
                error={errors.espessura}
                controlId="espessura"
                maxCharacters={120}
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("espessura")}
                  type="text"
                  placeholder="Insira o valor da espessura/gramatura" />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                label='Prop. Bandeira'
                error={errors.propBandeira}
                controlId="propBandeira"
                maxCharacters={120}
                style={{ marginBottom: 35 }}>
                <Form.Control
                  {...register("propBandeira")}
                  type="text"
                  placeholder="Insira o texto" />
              </FormControl>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormControl
                label='Range Prop. Band. 1'
                error={errors.rangePropBand1}
                controlId="rangePropBand1"
                maxCharacters={120}
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("rangePropBand1")}
                  type="text"
                  placeholder="Insira o texto" />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                label='Range Prop. Band. 2'
                error={errors.rangePropBand2}
                controlId="rangePropBand2"
                maxCharacters={120}
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("rangePropBand2")}
                  type="text"
                  placeholder="Insira o texto" />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                label='Range Prop. Band. 3'
                error={errors.rangePropBand3}
                controlId="rangePropBand3"
                maxCharacters={120}
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("rangePropBand3")}
                  type="text"
                  placeholder="Insira o texto" />
              </FormControl>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormControl
                label='Outras Características'
                error={errors.outrasCaracteristicas}
                controlId="outrasCaracteristicas"
                maxCharacters={3000}
                style={{ marginBottom: 20 }}>
                <FormControlTextArea
                  {...register("outrasCaracteristicas")}
                  height={55}
                  placeholder="Outras características" />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                label='Fase do Projeto'
                error={errors.faseProjeto}
                controlId="faseProjeto"
                maxCharacters={120}
                style={{ marginBottom: 35 }}>
                <Form.Control
                  {...register("faseProjeto")}
                  type="text"
                  placeholder="Insira a fase do projeto" />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                label='Data Prevista Para Finalização'
                error={errors.dataPrevistaFinalizacao}
                controlId="dataPrevistaFinalizacao"
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("dataPrevistaFinalizacao")}
                  type="date" />
              </FormControl>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormControl
                label='Responsável Pela Verificação'
                error={errors.usuarioVerificacao}
                controlId="usuarioVerificacao"
                maxCharacters={120}
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("usuarioVerificacao")}
                  type="text"
                  placeholder="Insira o usuário" />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                label='Data de Verificação'
                error={errors.dataVerificacao}
                controlId="dataVerificacao"
                style={{ marginBottom: 20 }}>
                <Form.Control
                  {...register("dataVerificacao")}
                  type="date" />
              </FormControl>
            </Col>
            <Col>
            </Col>
          </Row>
        </div>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: 20,
        }}>
          <Button
            size="lg"
            variant="outline-success"
            type="button"
            style={{ width: 146 }}
            onClick={() => isDirty ? setModalShow({
              type: DialogModalType.Warning,
              content: <span>Deseja descartar alterações?</span>,
              actions: [
                {
                  text: 'Não',
                  onClick: () => setModalShow(),
                  variant: 'outline-success',
                },
                {
                  text: 'Descartar',
                  onClick: () => {
                    navigate('/');
                    setModalShow();
                  },
                  variant: 'success',
                },
              ],
            }) : navigate('/')}>
            Voltar
          </Button>
          <div style={{ flex: 1 }}></div>
          <Button
            size="lg"
            variant="success"
            type="submit"
            disabled={findCodigoProjetoRequest.status === RequestStatus.Loading}
            style={{ width: 146 }}>Salvar</Button>
        </div>
      </Form>
    </div>)
}

export default EntradasTab;