import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { Card, Nav } from "react-bootstrap";
import { TabsFormTab } from "features/projetos/components/TabsFormTab";
import { useProjeto } from "features/projetos/providers/ProjetoProvider";
import Projeto from "features/projetos/models/Projeto";
import { useCallback, useEffect } from "react";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { useSelector } from "react-redux";
import { selectUserToken } from "features/auth/authSlice";
import { DialogModalType } from "features/common/components/DialogModal/DialogModal";
import { ValidationResult } from "features/common/models/ValidationResult";
import { useProjetoConcorrencia } from "./hooks/useProjetoConcorrencia";

const ProjetoEditPage = () => {
  const {
    progress: { setIsLoading },
    dialog: { setModalShow },
  } = useUIBehaviour();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const token = useSelector(selectUserToken) ?? "";
  const projetoId = searchParams.get('id') ?? "";

  const { setProjeto, projeto } = useProjeto();

  useEffect(() => {
    setIsLoading(Boolean(projetoId));
  }, [projetoId, setIsLoading]);

  const onStart = useCallback(() => {
  }, []);

  const onConnectionError = useCallback(() => {
    setIsLoading(false);
    setModalShow({
      type: DialogModalType.Warning,
      content: <span>Algo deu errado</span>,
      actions: [
        {
          text: 'Retornar',
          onClick: () => {
            setModalShow();
            navigate("/");
          },
          variant: 'success',
        },
      ],
    });
  }, [navigate, setIsLoading, setModalShow]);

  const onProjetoFetched = useCallback((projeto: Projeto) => {
    setIsLoading(false);
    setProjeto(projeto);
  }, [setIsLoading, setProjeto]);

  const onProjetoError = useCallback((statusCode: number, validationResult: ValidationResult) => {
    setIsLoading(false);

    let message = validationResult.notifications[0].message;
    setModalShow({
      type: DialogModalType.Warning,
      content: <span>{message}</span>,
      actions: [
        {
          text: 'Retornar',
          onClick: () => {
            setModalShow();
            navigate("/");
          },
          variant: 'success',
        },
      ],
    });
  }, [navigate, setIsLoading, setModalShow]);


  useProjetoConcorrencia({
    token,
    projetoId,
    projeto,
    onStart,
    onConnectionError,
    onProjetoFetched,
    onProjetoError,
  });

  return <div className="ProjetoEdit">
    <Card className="FormTabs">
      <Card.Header>
        <Nav variant="tabs">
          <TabsFormTab
            destination={'dados-do-projeto'}>
            Dados do Projeto
          </TabsFormTab>
          <TabsFormTab
            destination={'entradas'}
            disabled={!projetoId}
            disabledMessage={
              <span>É necessário salvar o Projeto</span>
            }>
            Dados de Entrada
          </TabsFormTab>
          <TabsFormTab
            destination={'analise-critica'}
            disabled={!projetoId}
            disabledMessage={
              <span>É necessário salvar o Projeto</span>
            }>
            Análise Crítica
          </TabsFormTab>
          <TabsFormTab
            destination={'validacoes'}
            disabled={!projetoId}
            disabledMessage={
              <span>É necessário salvar o Projeto</span>
            }>
            Validações
          </TabsFormTab>
        </Nav>
      </Card.Header>
      <Card.Body>
        <Outlet />
      </Card.Body>
    </Card>
  </div>
}

export default ProjetoEditPage;
