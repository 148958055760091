import axios, { AxiosResponse } from "axios";
import { useCallback } from "react";
import { useUIBehaviour } from "../providers/UIBehaviourProvider";

export const useDownloadFile = () => {
    const {
        dialog: { toast },
    } = useUIBehaviour();

    const download = useCallback(async <T>(url: string, fileName: string, data?: T) => {
        let response: AxiosResponse<any, any>;

        let queryString: string = "";

        if (data) {
            queryString = Object.keys(data).map(key => {
                let value = data[key as keyof T];

                if (!value) return null;

                return encodeURIComponent(key) + '=' + encodeURIComponent(value.toString())
            }).filter((item) => item !== null).join('&');
        }

        try {
            response = await axios({
                method: "GET",
                url: `${url}?${queryString}`,
                responseType: "blob",
            });
        } catch (ex) {
            alert(ex);
            toast.error("Algo deu errado");
            return;
        }

        const href = URL.createObjectURL(response.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
    }, [toast]);

    return { download };
};
