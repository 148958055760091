import { ProjetoProvider } from "features/projetos/providers/ProjetoProvider";
import ProjetoEditPage from "./ProjetoEditPage";

const index = () => {
    return (
        <ProjetoProvider>
            <ProjetoEditPage />
        </ProjetoProvider>
    );
};

export default index;