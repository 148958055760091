import { yupResolver } from "@hookform/resolvers/yup";
import yup from "config/yup";
import FormControl from "features/common/components/FormControl";
import FormControlTextArea from "features/common/components/FormControlTextArea";
import ListTile from "features/common/components/ListTile";
import ListTileColumn from "features/common/components/ListTileColumn";
import useRequest, { RequestStatus } from "features/common/hooks/useRequest";
import { ValidationResult } from "features/common/models/ValidationResult";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { Shape } from "features/common/types";
import { getValidacaoDadoDescription, ValidacaoDado as IValidacaoDado } from "features/projetos/models/Validacao";
import { useProjeto } from "features/projetos/providers/ProjetoProvider";
import createValidacaoDado from "features/projetos/services/validacao/createValidacaoDado";
import { FC, useCallback, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useCheckDateValidation } from "./useCheckDateValidation";

export interface ValidacaoDadoProps {
    validacaoDado: IValidacaoDado;
    changeIsDirty: (isDirty: boolean, tipo: number) => void;
    maxCharacters: number;
}

export interface ValidacaoDadoForm {
    descricao: string;
    data: string;
    responsavel: string;
    projetoId: string;
    tipo: number;
}

export const ValidacaoDado: FC<ValidacaoDadoProps> = ({
    validacaoDado,
    changeIsDirty,
    maxCharacters,
}) => {
    const { label: tipoLabel, placeholder: tipoPlaceholder } = getValidacaoDadoDescription(validacaoDado.tipo);

    const {
        progress: { setIsLoading },
        dialog: { toast },
    } = useUIBehaviour();

    const [searchParams] = useSearchParams();

    const { getValues, handleSubmit, register, reset, formState: { errors, isDirty } } = useForm<ValidacaoDadoForm>({
        resolver: yupResolver(yup.object<Shape<ValidacaoDadoForm>>({
            descricao: yup.string().max(maxCharacters).required().label(tipoLabel),
            data: yup.string().required().label("Data"),
            responsavel: yup.string().max(120).required().label("Responsável"),
            projetoId: yup.string().required(),
            tipo: yup.number().required(),
        }).required()),
    });

    const { tabIsDirty } = useProjeto();

    (window as any).tabIsDirty = tabIsDirty;

    const checkDateValidation = useCheckDateValidation();

    useEffect(() => {
        changeIsDirty(isDirty, validacaoDado.tipo);
    }, [isDirty, changeIsDirty, validacaoDado.tipo, tabIsDirty]);

    useEffect(() => {
        reset({
            descricao: validacaoDado.descricao,
            data: validacaoDado.data,
            responsavel: validacaoDado.responsavel,
            projetoId: searchParams.get("id") ?? "",
            tipo: validacaoDado.tipo,
        });
    }, [reset, searchParams, validacaoDado.data, validacaoDado.descricao, validacaoDado.responsavel, validacaoDado.tipo]);

    const { execute, status, failure } = useRequest<ValidacaoDadoForm, ValidationResult>(createValidacaoDado);

    useEffect(() => {
        setIsLoading(status === RequestStatus.Loading);

        if (status === RequestStatus.Success) {
            toast.success("Salvo com sucesso");
            reset(getValues());
        }

        if (status === RequestStatus.Failed) {
            toast.error(failure?.notifications?.[0].message ?? "Algo deu errado");
        }
    }, [failure?.notifications, getValues, reset, setIsLoading, status, toast]);

    const onSubmit = useCallback(async (form: ValidacaoDadoForm) => {
        await execute(form);
        checkDateValidation(getValues('data'));
    }, [execute, checkDateValidation, getValues]);

    return (
        <ListTile>
            <Form onSubmit={handleSubmit(onSubmit)} className="w-100">
                <Container fluid>
                    <Row className="mb-1 mx-0">
                        <div className="text-end">
                            <Button type="submit" variant="success">
                                Salvar
                            </Button>
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <ListTileColumn label="">
                                <FormControl
                                    label={tipoLabel}
                                    error={errors.descricao}
                                    controlId="descricao"
                                    maxCharacters={maxCharacters}
                                    style={{ marginBottom: 20 }}
                                    className="fw-normal">
                                    <FormControlTextArea
                                        {...register("descricao")}
                                        height={55}
                                        placeholder={tipoPlaceholder} />
                                </FormControl>
                            </ListTileColumn>
                        </Col>
                        <Col>
                            <ListTileColumn label="">
                                <FormControl
                                    label='Data'
                                    error={errors.data}
                                    controlId="data"
                                    style={{ marginBottom: 20 }}
                                    className="fw-normal">
                                    <Form.Control
                                        {...register("data")}
                                        type="date"
                                        placeholder="Insira a data" />
                                </FormControl>
                            </ListTileColumn>
                        </Col>
                        <Col>
                            <ListTileColumn label="">
                                <FormControl
                                    label='Responsável'
                                    error={errors.responsavel}
                                    controlId="responsavel"
                                    style={{ marginBottom: 20 }}
                                    className="fw-normal">
                                    <Form.Control
                                        {...register("responsavel")}
                                        type="text"
                                        placeholder="Insira o responsável" />
                                </FormControl>
                            </ListTileColumn>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </ListTile>
    )
};