import { FC, JSXElementConstructor, ReactElement, useCallback, useEffect, useState } from "react";
import { Button, Form, Offcanvas, OffcanvasProps } from "react-bootstrap";
import ListTile from "../../../../../common/components/ListTile";
import Thrash from "../../../../../../assets/thrash.png";
import PaginationBase from "features/common/models/PaginationBase";
import { Requisito } from "features/projetos/models/Requisito";
import { PagedResult } from "features/common/models/PagedResult";
import useRequest, { RequestStatus } from "features/common/hooks/useRequest";
import { useAdicionarRequisitoModal } from "./useAdicionarRequisitoModal";
import { DialogModalType } from "features/common/components/DialogModal/DialogModal";
import ListTileColumn from "features/common/components/ListTileColumn";
import { ApplicationPagination } from "features/common/components/ApplicationPagination";
import { useSearchParams } from "react-router-dom";
import { ValidationResult } from "features/common/models/ValidationResult";
import List from "features/common/components/List";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { fetchRequisitos } from "features/projetos/services/requisito/fetchRequisitos";
import { deleteRequisito, DeleteRequisitoCommand } from "features/projetos/services/requisito/deleteRequisito";
import { updateRequisito } from "features/projetos/services/requisito/updateRequisito";
import { useProjeto } from "features/projetos/providers/ProjetoProvider";
import { StatusProjeto } from "features/projetos/models/Projeto";
import { stringOrEmpty } from "features/common/utils";
import closeIcon from "../../../../../../assets/icon-close.png";

interface RequisitosSplittedFormTabItemProps extends OffcanvasProps {
    isVisible: boolean;
    destination: string;
    setIsVisible: (isVisible: boolean) => void;
    children?: ReactElement<any, string | JSXElementConstructor<any>> | ReactElement<any, string | JSXElementConstructor<any>>[] | undefined;
}

export interface RequisitoRequest extends PaginationBase {
    projetoId: string | undefined | null;
}

export interface UpdateRequisitoRequest {
    projetoId: string;
    requisitoId: string;
    situacao: boolean;
}

export interface RequisitosProjetoForm {
    requisitos: Requisito[];
}

export const RequisitosOffCanvas: FC<RequisitosSplittedFormTabItemProps> = ({
    isVisible,
    setIsVisible,
    children,
    onHide,
    destination,
}) => {
    const {
        progress: { setIsLoading },
        dialog: { setModalShow, toast },
    } = useUIBehaviour();
    const { projeto } = useProjeto();

    const [requisitos, setRequisitos] = useState<PagedResult<Requisito>>();

    const fetchRequest = useRequest<RequisitoRequest, PagedResult<Requisito>>(fetchRequisitos);
    const deleteRequest = useRequest<DeleteRequisitoCommand, ValidationResult>(deleteRequisito);
    const updateRequest = useRequest<UpdateRequisitoRequest, ValidationResult>(updateRequisito);

    const [searchParams] = useSearchParams();

    const fetchRequisitosCallback = useCallback(() => {
        fetchRequest.execute({
            projetoId: searchParams.get("id"),
            page: parseInt(searchParams.get("page") || '1'),
            take: 4
        });
    }, [fetchRequest.execute, searchParams]);

    useEffect(() => {
        fetchRequisitosCallback();
    }, [fetchRequisitosCallback]);

    useEffect(() => {
        setIsLoading(
            fetchRequest.status === RequestStatus.Loading ||
            deleteRequest.status === RequestStatus.Loading ||
            updateRequest.status === RequestStatus.Loading);
    }, [
        fetchRequest.status,
        deleteRequest.status,
        updateRequest.status,
    ]);

    useEffect(() => {
        if (fetchRequest.status === RequestStatus.Success) {
            setRequisitos(fetchRequest.data);
        }

        if (fetchRequest.status === RequestStatus.Failed) {
            toast.error("Algo inesperado aconteceu");
        }
    }, [fetchRequest.status]);

    useEffect(() => {
        if (updateRequest.status === RequestStatus.Success) {
            toast.success('Requisito atualizado');
        }

        if (updateRequest.status === RequestStatus.Failed) {
            toast.error("Algo inesperado aconteceu");
        }
    }, [updateRequest.status]);

    useEffect(() => {
        if (deleteRequest.status === RequestStatus.Success) {
            fetchRequisitosCallback();

            if (deleteRequest.data?.message) {
                setModalShow({
                    title: 'Requisito excluído',
                    content: <span>{deleteRequest.data?.message}</span>,
                    type: DialogModalType.Warning,
                    actions: [
                        {
                            text: 'OK',
                            onClick: () => setModalShow(),
                            variant: 'success',
                        }
                    ]
                });
            } else {
                toast.success("Deletado com sucesso");
            }
        }

        if (deleteRequest.status === RequestStatus.Failed) {
            const notifications = deleteRequest.failure?.notifications;
            if (notifications && notifications.length > 0) {
                setModalShow({
                    title: 'Requisito não excluído',
                    content: <span>{notifications[0].message}</span>,
                    type: DialogModalType.Warning,
                    actions: [
                        {
                            text: 'OK',
                            onClick: () => setModalShow(),
                            variant: 'success',
                        }
                    ]
                });
            } else {
                toast.error("Algo inesperado aconteceu");
            }
        }
    }, [deleteRequest.status]);

    const { show: showAdicionarRequisito } = useAdicionarRequisitoModal(fetchRequisitosCallback);

    const deleteRequisitoCallback = useCallback(async (requisitoId: string) => {
        setModalShow({
            type: DialogModalType.Warning,
            footer: {
                className: "justify-content-between"
            },
            content: <span>Deseja excluir o requisito selecionado? Após a exclusão, não será possível recuperar o mesmo.</span>,
            actions: [
                {
                    text: 'Não',
                    onClick: () => setModalShow(),
                    variant: 'outline-success',
                },
                {
                    text: 'Sim',
                    onClick: async () => {
                        await deleteRequest.execute({
                            projetoId: stringOrEmpty(searchParams.get("id")),
                            requisitoId,
                        });
                        setModalShow();
                    },
                    variant: 'success',
                },
            ],
        })
    }, [deleteRequest, fetchRequisitosCallback, setModalShow, toast]);

    const updateRequisitoCallback = useCallback(async (requisitoId: string, situacao: boolean) => {
        const projetoId = searchParams.get("id");

        if (projetoId === null) return;

        if (requisitos) {
            requisitos.records.map(requisito => {
                if (requisito.id === requisitoId) {
                    requisito.situacao = situacao;
                }

                return requisito;
            });

            setRequisitos({ ...requisitos });
        }

        await updateRequest.execute({
            projetoId: searchParams.get("id") ?? "",
            requisitoId,
            situacao
        });
    }, [searchParams, toast, updateRequest]);

    const getSituacao = useCallback((situacao: boolean | undefined) => {
        if (situacao === null) return "";
        return situacao ? 1 : 0;
    }, []);

    function onHideHandler() {
        onHide();
        setIsVisible(false);
    }

    return (
        <Offcanvas
            style={{ width: 600, height: "100vh" }}
            show={isVisible}
            placement="end"
            onHide={onHideHandler}
        >
            <Offcanvas.Header>
                <div className="w-100 d-flex justify-content-between border-bottom pb-2">
                    <Offcanvas.Title className="">Configuração de Requisitos</Offcanvas.Title>
                    <Button
                        className="cursor-pointer p-0"
                        onClick={onHideHandler}
                        variant="link">
                        <img alt="Fechar Modal" src={closeIcon} />
                    </Button>
                </div>
            </Offcanvas.Header>

            <Offcanvas.Body className="overflow-auto d-flex flex-column">

                <div className="d-flex align-items-center mb-4">
                    <h4 className="me-4 mb-0">Novo Requisito</h4>
                    <Button onClick={() => showAdicionarRequisito()}>Adicionar</Button>
                </div>

                <div className="overflow-auto">
                    <List className="ProjetosList">
                        {(requisitos && requisitos.records) ? (
                            requisitos.records.map((requisito, key) => (
                                <div className="my-2" key={key}>
                                    <ListTile
                                        end={
                                            projeto?.status == StatusProjeto.EmAndamento && (
                                                <div className="h-100 d-flex align-items-center">
                                                    <Button variant="link" onClick={() => deleteRequisitoCallback(requisito.id)}>
                                                        <img alt="Deletar" src={Thrash} />
                                                    </Button>
                                                </div>
                                            )}
                                    >
                                        <ListTileColumn label="Nome" flex={1}>
                                            <span>{requisito.nome}</span>
                                        </ListTileColumn>

                                        <ListTileColumn label="Situação" flex={1}>
                                            <Form.Select
                                                value={getSituacao(requisito.situacao)}
                                                onChange={(e) => updateRequisitoCallback(requisito.id, e.target.value === "1")}>
                                                <option style={{ display: "none" }} value=""></option>
                                                <option value={1}>Sim</option>
                                                <option value={0}>Não</option>
                                            </Form.Select>
                                        </ListTileColumn>
                                    </ListTile>
                                </div>
                            ))
                        ) : (
                            <span></span> // TODO Incluir Skeleton
                        )}
                    </List>

                    <div className="d-flex justify-content-end align-items-center bg-none p-3">
                        <ApplicationPagination
                            itensPerPage={4}
                            totalItems={requisitos?.recordsTotal}
                            itensQuantity={requisitos?.records.length ?? 0} />
                    </div>
                </div>

                {children}
            </Offcanvas.Body>

        </Offcanvas>
    );
};
