import { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import FormControlTextArea from "features/common/components/FormControlTextArea";
import Projeto, { TipoAbertura, StatusProjeto } from "features/projetos/models/Projeto";
import { Shape } from "features/common/types";
import FormControl from "features/common/components/FormControl";
import useRequest, { RequestStatus } from "features/common/hooks/useRequest";
import { stringOrEmpty, useNavigateWithId } from "features/common/utils";
import { useForm } from "react-hook-form";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { ValidationResult } from "features/common/models/ValidationResult";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DialogModalType } from "features/common/components/DialogModal/DialogModal";
import { useProjeto } from "features/projetos/providers/ProjetoProvider";
import yup from "config/yup";
import { findCodigoProjeto } from "features/projetos/services/projeto/findCodigoProjeto";
import updateProjeto from "features/projetos/services/projeto/updateProjeto";
import createProjeto from "features/projetos/services/projeto/createProjeto";
import { useAppSelector } from "features/common/redux/hooks";
import { selectCurrentUserDivision } from "features/auth/authSlice";
import moment from "moment";

export interface DadosDoProjetoForm {
  codigoProjeto: string;
  tipoAbertura: TipoAbertura;
  status: StatusProjeto;
  dataAbertura: string;
  divisao: string;
  objetivoProjeto: string;
  definicaoProjeto: string;
  usuarioVerificacao: string;
  dataVerificacao: string;
  valid?: boolean;
}

export const dadosDoProjetoSchema = yup.object<Shape<DadosDoProjetoForm>>({
  codigoProjeto: yup.string().max(120).required().label("Código do Projeto"),
  tipoAbertura: yup.number().typeError("É necessário selecionar uma opção").required().label("Tipo de Abertura"),
  status: yup.number().typeError("É necessário selecionar uma opção").required().label("Status"),
  dataAbertura: yup.string().required().label("Data de abertura"),
  divisao: yup.string().required().label("Divisão"),
  objetivoProjeto: yup.string().max(500).required().label("Objetivo do Projeto"),
  definicaoProjeto: yup.string().max(500).required().label("Definicão do Projeto"),
  usuarioVerificacao: yup.string().max(120).required().label("Usuário de Verificação"),
  dataVerificacao: yup.string().required().label("Data de Verificação"),
}).required();

const DadosDoProjetoTab = () => {
  const navigate = useNavigate();
  const navigateWithId = useNavigateWithId();
  const [idParam, setIdParam] = useState<string>();
  const findCodigoProjetoRequest = useRequest<string, boolean>(
    data => findCodigoProjeto(data));
  const {
    progress: { setIsLoading },
    dialog: { setModalShow, toast },
  } = useUIBehaviour();

  const [searchParams] = useSearchParams();
  const projetoId = searchParams.get("id");

  const { setProjeto, projeto, setTabIsDirty } = useProjeto();

  const {
    handleSubmit,
    register,
    reset,
    getValues,
    formState: {
      errors,
      isDirty,
    }
  } = useForm<DadosDoProjetoForm>({
    resolver: yupResolver(dadosDoProjetoSchema),
  });

  const createRequest =
    useRequest<Projeto, ValidationResult>(data => createProjeto(data));
  const updateRequest =
    useRequest<Projeto, ValidationResult>(data => updateProjeto(data));

  useEffect(() => {
    setIdParam(stringOrEmpty(projetoId));
  }, [projetoId]);

  useEffect(() => {
    reset({
      ...projeto,
      divisao: division?.name,
      dataAbertura: moment(projeto?.dataAbertura).format('YYYY-MM-DD'),
      dataVerificacao: moment(projeto?.dataVerificacao).format('YYYY-MM-DD'),
    });
  }, [projeto, reset]);

  useEffect(() => {
    setIsLoading(updateRequest.status === RequestStatus.Loading ||
      createRequest.status === RequestStatus.Loading);
  }, [
    updateRequest.status,
    createRequest.status,
  ]);

  useEffect(() => {
    if (updateRequest.status === RequestStatus.Success ||
      createRequest.status === RequestStatus.Success) {
      toast.success('Projeto salvo');
    }

    if (createRequest.status === RequestStatus.Success) {
      navigate(`?id=${createRequest.data?.id}`);
    }

    if (updateRequest.status === RequestStatus.Success) {
      if (idParam) {
        setProjeto(getValues());
        reset({ ...getValues() });
      }
    }

    if (createRequest.status === RequestStatus.Failed) {
      toast.error("Algo inesperado aconteceu");
    }

    if (updateRequest.status === RequestStatus.Failed) {
      const notifications = updateRequest.failure?.notifications;
      if (notifications && notifications.length > 0) {
        if (notifications[0].message.includes('Existem requisitos não preenchidos')) {
          setModalShow({
            title: 'Requisitos não preenchidos',
            content: <span>Há Requisitos não preenchidos para este projeto. Por favor, vá até a aba <strong>Entradas</strong> {">"} <strong>botão Requisitos</strong> e efetue o preenchimento para continuar as alterações.</span>,
            type: DialogModalType.Warning,
            actions: [
              {
                text: 'OK',
                onClick: () => setModalShow(),
                variant: 'success',
              }
            ],
          });
        } else {
          toast.error("Algo inesperado aconteceu");
        }
      } else {
        toast.error("Algo inesperado aconteceu");
      }
    }
  }, [
    updateRequest.status,
    createRequest.status,
  ]);

  useEffect(() => {
    setTabIsDirty(isDirty);
  }, [isDirty]);

  function saveHandler(form: Projeto) {
    if (projetoId) {
      updateRequest.execute(form);
    } else {
      createRequest.execute(form);
    }
  }

  const checaSeCodigoProjetoExiste = useCallback((data: any) => {
    const codigo = data.target.value.trim();
    if (codigo && (codigo !== projeto?.codigoProjeto.trim())) {
      findCodigoProjetoRequest.execute(codigo);
    }
  }, []);

  const division = useAppSelector(selectCurrentUserDivision);

  return <div className="DadosDoProjetoTab">
    <div style={{ display: 'flex' }}>
      <h4>Dados do Projeto</h4>
      <div style={{ flex: 1 }}></div>
      <Button
        style={{ height: 'max-content' }}
        variant='primary'
        type='button'
        disabled={!idParam}
        onClick={() => navigateWithId('tex')}>TEX's do Projeto</Button>
    </div>

    <div style={{ height: 20 }}></div>

    <Form onSubmit={handleSubmit(saveHandler)}>
      <Row>
        <Col>
          <FormControl
            label="Código do Projeto"
            loading={findCodigoProjetoRequest.status === RequestStatus.Loading}
            error={errors.codigoProjeto || (findCodigoProjetoRequest.data ? {
              type: 'custom',
              message: 'Já existe um projeto com este código',
            } : undefined)}
            controlId="codigoProjeto"
            maxCharacters={120}
            style={{ marginBottom: 20 }}>
            <Form.Control
              {...register("codigoProjeto")}
              type="text"
              disabled={Boolean(projetoId)}
              onBlur={checaSeCodigoProjetoExiste}
              style={{
                paddingRight: "2.8rem",
              }}
              placeholder="Insira o código do projeto"
            />
          </FormControl>
        </Col>
        <Col>
          <FormControl
            label='Data de Abertura'
            error={errors.dataAbertura}
            controlId="dataAbertura"
            style={{ marginBottom: 20 }}>
            <Form.Control
              {...register("dataAbertura")}
              type="date" disabled />
          </FormControl>
        </Col>
        <Col>
          <FormControl
            label='Tipo de Abertura'
            error={errors.tipoAbertura}
            controlId="tipoAbertura"
            style={{ marginBottom: 20 }}>
            <Form.Select {...register("tipoAbertura")}>
              <option>Selecione uma opção</option>
              <option value={TipoAbertura.NovoProduto}>Novo Produto</option>
              <option value={TipoAbertura.Homologacao}>Homologação MP</option>
              <option value={TipoAbertura.Otimizacao}>Otimização</option>
            </Form.Select>
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormControl
            label='Divisão'
            error={errors.divisao}
            controlId="divisao"
            style={{ marginBottom: 35 }}>
            <Form.Select disabled>
              <option value={division?.name}>{division?.name}</option>
            </Form.Select>
          </FormControl>
        </Col>
        <Col>
          <FormControl
            label="Definição do Projeto"
            error={errors.definicaoProjeto}
            controlId="definicaoProjeto"
            maxCharacters={500}
            style={{ marginBottom: 20 }}>
            <FormControlTextArea
              {...register("definicaoProjeto")}
              placeholder="Insira o texto aqui..."
              height={55}
              style={{
                paddingRight: "2rem"
              }}
            />
          </FormControl>
        </Col>
        <Col>
          <FormControl
            label='Objetivo do Projeto'
            error={errors.objetivoProjeto}
            controlId="objetivoProjeto"
            maxCharacters={500}
            style={{ marginBottom: 20 }}>
            <FormControlTextArea
              {...register("objetivoProjeto")}
              placeholder="Insira o texto aqui..."
              height={55} />
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormControl
            label='Status'
            error={errors.status}
            controlId="status"
            style={{ marginBottom: 20 }}>
            <Form.Select {...register("status")}>
              <option style={{ display: "none" }}>Selecione uma opção</option>
              <option value={StatusProjeto.EmAndamento}>Em Andamento</option>
              <option value={StatusProjeto.AprovadoConcluido}>Aprovado/Concluído</option>
              <option value={StatusProjeto.Suspenso}>Suspenso</option>
              <option value={StatusProjeto.Cancelado}>Cancelado</option>
            </Form.Select>
          </FormControl>
        </Col>

        <Col>
          <FormControl
            label='Responsável Pela Verificação'
            error={errors.usuarioVerificacao}
            controlId="usuarioVerificacao"
            maxCharacters={120}
            style={{ marginBottom: 20 }}>
            <Form.Control
              {...register("usuarioVerificacao")}
              placeholder="Insira o nome do usuário"
              type="text" />
          </FormControl>
        </Col>

        <Col>
          <FormControl
            label='Data de Verificação'
            error={errors.dataVerificacao}
            controlId="dataVerificacao"
            style={{ marginBottom: 20 }}>
            <Form.Control
              {...register("dataVerificacao")}
              type="date" />
          </FormControl>
        </Col>
      </Row>

      <div style={{
        display: 'flex',
        alignItems: 'center',
        paddingTop: 20,
      }}>
        <Button
          size="lg"
          variant="outline-success"
          type="button"
          style={{ width: 146 }}
          onClick={() => isDirty ? setModalShow({
            type: DialogModalType.Warning,
            content: <span>Deseja descartar alterações?</span>,
            actions: [
              {
                text: 'Não',
                onClick: () => setModalShow(),
                variant: 'outline-success',
              },
              {
                text: 'Descartar',
                onClick: () => {
                  navigate('/');
                  setModalShow();
                },
                variant: 'success',
              },
            ],
          }) : navigate('/')}>
          Voltar
        </Button>
        <div style={{ flex: 1 }}></div>
        <Button
          size="lg"
          variant="success"
          type="submit"
          disabled={findCodigoProjetoRequest.status === RequestStatus.Loading}
          style={{ width: 146 }}>Salvar</Button>
      </div>
    </Form>
  </div>
}

export default DadosDoProjetoTab;