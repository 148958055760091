import { CSSProperties, JSXElementConstructor, ReactElement } from "react";
import { Button, Modal, ModalProps } from "react-bootstrap";
import {
  ExclamationTriangleFill,
  CheckCircleFill,
  InfoCircle,
  XCircle,
} from "react-bootstrap-icons";
import SuccessIcon from 'assets/modal/success-icon.svg';
import WarningIcon from 'assets/modal/warning-icon.svg';
import { appendClassName } from "features/common/utils";
import './style.scss';

export enum DialogModalType {
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
  Error = 'danger',
}

export interface DialogModalAction {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'success' | 'warning' |
  'danger' | 'info' | 'outline-primary' | 'outline-secondary' |
  'outline-success' | 'outline-warning' | 'outline-danger' | 'outline-info';
}

export interface DialogModalFooter {
  className?: string;
  style?: CSSProperties;
}

export type DialogModalProps = {
  title?: string;
  content?: ReactElement<any, string | JSXElementConstructor<any>> |
  ReactElement<any, string | JSXElementConstructor<any>>[] | undefined;
  actions?: DialogModalAction[];
  type?: DialogModalType;
  footer?: DialogModalFooter;
}

const DialogModal = (props: ModalProps & DialogModalProps) => {
  return <Modal
    {...props}
    className={appendClassName('DialogModal', props.className)}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onExited={props.onExited}>
    <Modal.Body>
      {props.type === DialogModalType.Success && <img src={SuccessIcon} />}
      {props.type === DialogModalType.Warning && <img src={WarningIcon} />}
      {props.type === DialogModalType.Info && <InfoCircle size={120} />}
      {props.type === DialogModalType.Error && <XCircle className="text-danger" size={120} />}
      <div style={{ height: 20 }}></div>
      {props.title && <Modal.Title id="contained-modal-title-vcenter">
        {props.title}
      </Modal.Title>}
      <div style={{ height: 10 }}></div>
      {props.content}
    </Modal.Body>
    {props.actions && <Modal.Footer className={`modal-actions-wrapper ${props.footer?.className}`} style={props.footer?.style}>
      {props.actions.map((action, key) =>
        <Button
          size="lg"
          key={key}
          variant={action.variant}
          onClick={action.onClick}
          disabled={action.disabled}>{action.text}</Button>)}
    </Modal.Footer>}
  </Modal>
};

export default DialogModal;