import useRequest, { RequestStatus } from "features/common/hooks/useRequest";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { ETipoValidacao, Validacao } from "features/projetos/models/Validacao";
import fetchValidacao from "features/projetos/services/validacao/fetchValidacao";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useFetchValidacao = () => {
    const {
        progress: { setIsLoading },
        dialog: { toast },
    } = useUIBehaviour();

    const [searchParams] = useSearchParams();

    const [validacao, setValidacao] = useState<Validacao>({
        dados: []
    });

    const { execute, status, data, failure } = useRequest<string, Validacao>(fetchValidacao);

    useEffect(() => {
        execute(searchParams.get("id") ?? "");
    }, [execute, searchParams]);

    useEffect(() => {
        setIsLoading(status === RequestStatus.Loading);

        if (status === RequestStatus.Success) {
            setValidacao(data ?? {
                dados: []
            });
        }

        if (status === RequestStatus.Failed) {
            toast.error(failure?.notifications?.[0].message ?? "Algo deu errado");
        }
    }, [data, failure?.notifications, setIsLoading, status, toast]);

    const codigoProduto = validacao?.dados?.filter((dado) => dado.tipo === ETipoValidacao.DefinicaoCodigoProduto)[0] ?? { tipo: ETipoValidacao.DefinicaoCodigoProduto };
    const envioDocumento = validacao?.dados?.filter((dado) => dado.tipo === ETipoValidacao.EnvioDocumento)[0] ?? { tipo: ETipoValidacao.EnvioDocumento };
    const atualizacaoDocumento = validacao?.dados?.filter((dado) => dado.tipo === ETipoValidacao.AtualizacaoDocumentoRegulamentar)[0] ?? { tipo: ETipoValidacao.AtualizacaoDocumentoRegulamentar };
    const conclusaoProjeto = validacao?.dados?.filter((dado) => dado.tipo === ETipoValidacao.ConclusaoProjetoValidacao)[0] ?? { tipo: ETipoValidacao.ConclusaoProjetoValidacao };

    return {
        validacao: data,
        codigoProduto,
        envioDocumento,
        atualizacaoDocumento,
        conclusaoProjeto,
    };
};