import {
  createContext,
  JSXElementConstructor,
  ReactElement,
  useContext,
  useState,
} from "react";
import Projeto from "../models/Projeto";

interface ProjetoProviderProps {
  projeto: Projeto | undefined;
  setProjeto: React.Dispatch<React.SetStateAction<Projeto | undefined>>;
  tabIsDirty: boolean;
  setTabIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjetoContext = createContext({} as ProjetoProviderProps);

export const ProjetoProvider = ({ children }: {
  children?: ReactElement<any, string | JSXElementConstructor<any>> |
  ReactElement<any, string | JSXElementConstructor<any>>[] | undefined;
}) => {
  const [projeto, setProjeto] = useState<Projeto>();
  const [tabIsDirty, setTabIsDirty] = useState(false);

  return <ProjetoContext.Provider value={{
    projeto,
    setProjeto,
    tabIsDirty, 
    setTabIsDirty,
  }}>
    {children}
  </ProjetoContext.Provider>
}

export function useProjeto() {
  return useContext<ProjetoProviderProps>(ProjetoContext);
}