import { DialogModalType } from "features/common/components/DialogModal/DialogModal";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { useCallback } from "react";

export const useCheckDateValidation = () => {

    const {
        dialog: { setModalShow },
    } = useUIBehaviour();


    const checkDateValidation = useCallback((dateString: string) => {
        let currentDate = new Date();
        currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

        let date = new Date(dateString);
        let timeDiff = date.getTime() - currentDate.getTime();

        const timeToDays = 1000 * 3600 * 24;
        let diffDays = Math.ceil(timeDiff / timeToDays);

        if (Math.abs(diffDays) < 365)
            return;

        let content;

        if (diffDays >= 365) {
            content = "Atenção data maior que 365 dias.";
        } else {
            content = "Atenção data menor que 365 dias.";
        }

        setModalShow({
            type: DialogModalType.Warning,
            content: <span>{content}</span>,
            actions: [
                {
                    text: 'OK',
                    onClick: () => setModalShow(),
                    variant: 'outline-success',
                }
            ],
        })
    }, [setModalShow]
    );
    return checkDateValidation
}