import { appendClassName } from "features/common/utils";
import { FC, HTMLAttributes, ReactNode } from "react";
import './style.scss';

interface ListTileColumnProps {
  label: string;
  children: ReactNode;
  flex?: number,
}

const ListTileColumn: FC<ListTileColumnProps & HTMLAttributes<HTMLDivElement>> = ({
  label, children, flex, ...props
}) => {
  return <div 
  {...props}
  className={appendClassName("ListTileColumn", props.className)} 
  style={{
    marginLeft: 10,
    marginRight: 10,
    flex,
    ...props.style,
  }}>
    <h6>{label}</h6>
    {children}
  </div>
}

export default ListTileColumn;