import List from "features/common/components/List";
import ListTile from "features/common/components/ListTile";
import ListTileColumn from "features/common/components/ListTileColumn";
import useRequest, { RequestStatus } from "features/common/hooks/useRequest";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Button, Col, Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import Projeto, {
  mapStatusProjetoToColor,
  mapStatusProjetoToString,
  mapTipoAberturaToString,
  StatusProjeto,
  TipoAbertura,
} from "../models/Projeto";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import TextTruncate from "react-text-truncate";
import DropdownActions from "features/common/components/DropdownActions";
import { ApplicationPagination } from "features/common/components/ApplicationPagination";
import { Filter } from "features/common/components/Filter";
import FormControl from "features/common/components/FormControl";
import { useForm } from "react-hook-form";
import { PagedResult } from "features/common/models/PagedResult";
import EmptyListImage from 'assets/empty-list.png';
import { fetchProjetos, ProjetoFilters } from "../services/projeto/fetchProjetos";
import { formatDateString } from "features/common/utils";
import { debounce } from "lodash";

const ProjetosListPage = () => {
  const navigate = useNavigate();
  const { register, watch, getValues } = useForm<ProjetoFilters>();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [projetos, setProjetos] = useState<PagedResult<Projeto>>();
  const {
    progress: { setIsLoading },
    dialog: { toast },
  } = useUIBehaviour();

  const [searchParams] = useSearchParams();

  const { execute, status, data } = useRequest(fetchProjetos);

  const getProjectListCallback = useCallback((data: ProjetoFilters) => {
    execute({
      codigo: data["codigo"],
      dataAbertura: data["dataAbertura"],
      status: data["status"],
      tipoAbertura: data["tipoAbertura"],
      page: parseInt(searchParams.get("page") || '1'),
      take: 4,
    });
  }, [execute, searchParams]);

  useEffect(() => {
    getProjectListCallback(getValues());
  }, [getProjectListCallback, getValues]);

  useEffect(() => {
    const subscription = watch((value) => {
      debounce(() => {
        getProjectListCallback({
          ...value,
          page: parseInt(searchParams.get("page") || '1'),
          take: 4,
        });
      }, 200)();
    });
    return () => subscription.unsubscribe();
  }, [getProjectListCallback, searchParams, watch]);

  useEffect(() => {
    setIsLoading(status === RequestStatus.Loading);

    if (status === RequestStatus.Success) {
      setProjetos(data);
    }

    if (status === RequestStatus.Failed) {
      toast.error("Algo inesperado aconteceu");
    }
  }, [data, setIsLoading, status, toast]);

  return (
    <div className="ProjetosListPage">
      <div style={{
        display: "flex",
      }}>
        <h3>Projetos</h3>
        <div style={{ width: 20 }}></div>
        <div>
          <Button
            size="lg"
            variant="primary"
            type="button"
            onClick={() => navigate("edit/dados-do-projeto")}>
            Adicionar
          </Button>
        </div>
        <div style={{ flex: 1 }}></div>
        <div>
          <Button
            size="lg"
            variant="primary"
            type="button"
            onClick={() => setIsFilterVisible((isVisible) => !isVisible)}>
            Filtrar
          </Button>
        </div>
      </div>

      {/* TODO limpar todos os filtros quando o componente for fechado
          como é feito no filtro da tela TEX */}
      <Filter.Container
        isVisible={isFilterVisible}
        setIsVisible={setIsFilterVisible}
      >
        <Col>
          <FormControl label="Código do Projeto" controlId="codigo">
            <Form.Control
              {...register("codigo")}
              type="text"
              placeholder="Pesquise"
            />
          </FormControl>
        </Col>

        <Col>
          <FormControl label="Data de Abertura" controlId="dataAbertura">
            <Form.Control {...register("dataAbertura")} type="date" />
          </FormControl>
        </Col>

        <Col>
          <FormControl label="Status" controlId="status">
            <Form.Select {...register("status")}>
              <option value="">Selecione uma opção</option>
              <option value={StatusProjeto.EmAndamento}>Em Andamento</option>
              <option value={StatusProjeto.AprovadoConcluido}>
                Aprovado/Concluído
              </option>
              <option value={StatusProjeto.Suspenso}>Suspenso</option>
              <option value={StatusProjeto.Cancelado}>Cancelado</option>
            </Form.Select>
          </FormControl>
        </Col>

        <Col>
          <FormControl label="Tipo de Abertura" controlId="tipoAbertura">
            <Form.Select {...register("tipoAbertura")}>
              <option value="">Selecione uma opção</option>
              <option value={TipoAbertura.NovoProduto}>Novo Produto</option>
              <option value={TipoAbertura.Homologacao}>Homologação MP</option>
              <option value={TipoAbertura.Otimizacao}>Otimização</option>
            </Form.Select>
          </FormControl>
        </Col>
      </Filter.Container>

      <h5>Listagem</h5>

      <div style={{ height: 10 }}></div>

      <List className="ProjetosList" gap={15}>

        {!projetos?.records.length && (
          <div className="d-flex justify-content-center">
            <img src={EmptyListImage} alt="Nenhuma informação encontrada" />
          </div>
        )}

        {projetos?.records.map((projeto, key) => (
          <ListTile
            key={key}
            end={
              <DropdownActions
                actions={[
                  {
                    text: "Editar",
                    onClick: () =>
                      navigate(`edit/dados-do-projeto?id=${projeto.id}`),
                  },
                ]} />
            }>
            <ListTileColumn label="Projeto" flex={1}>
              <span>{projeto.codigoProjeto}</span>
            </ListTileColumn>

            <ListTileColumn label="Definição" flex={3}>
              {/* TODO passar o TextTruncate para dentro de ListTileColumn
                      e expor a prop lines */}
              <TextTruncate text={projeto.definicaoProjeto} line={2} />
            </ListTileColumn>

            <ListTileColumn label="Dados de Entrada" flex={3}>
              <TextTruncate text={projeto.dadosEntrada} line={2} />
            </ListTileColumn>

            <ListTileColumn label="Data Abertura" flex={1}>
              <span>{formatDateString(projeto.dataAbertura)}</span>
            </ListTileColumn>

            <ListTileColumn label="Tipo de Abertura" flex={1}>
              {mapTipoAberturaToString.get(projeto.tipoAbertura as TipoAbertura)}
            </ListTileColumn>

            <ListTileColumn label="Status" flex={1}>
              <Badge bg={mapStatusProjetoToColor.get(projeto.status as StatusProjeto)}>
                {mapStatusProjetoToString.get(projeto.status as StatusProjeto)}
              </Badge>
            </ListTileColumn>
          </ListTile>
        ))}

        <ApplicationPagination itensPerPage={4} totalItems={data?.recordsTotal} itensQuantity={data?.records.length ?? 0} />
      </List>
    </div>
  );
};

export default ProjetosListPage;

