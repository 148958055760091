import { useCallback, useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { ApplicationPaginationProp } from "./types";
import "./style.css";

export const ApplicationPagination = ({
  pageQuantity,
  itensPerPage,
  totalItems,
  itensQuantity,
  visiblePageQuantity = 5,
  changeUrl = true,
  style,
}: ApplicationPaginationProp) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);

  const onClick = useCallback(
    (page: number) => {
      if (!changeUrl) return;

      setSearchParams({
        ...Object.fromEntries(searchParams),
        page: page.toString(),
      });
    },
    [changeUrl, searchParams, setSearchParams]
  );

  const quantity = pageQuantity
    ? pageQuantity
    : Math.ceil((totalItems ?? 0) / (itensPerPage ?? 1));

  useEffect(() => {
    let page = parseInt(searchParams.get("page") ?? "1");

    if (page > quantity && quantity > 0) {
      page = quantity;
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page: quantity.toString(),
      });
    }

    if (page < 1) {
      page = 1;
      setSearchParams({ ...Object.fromEntries(searchParams), page: "1" });
    }

    setCurrentPage(page);
  }, [quantity, searchParams, setSearchParams]);

  const range = (start: number, end: number) => {
    return Array(end)
      .fill(start)
      .map((x, y) => x + y)
      .filter((x) => x > 0 && x <= quantity)
  };

  const otherPages = (visiblePageQuantity - 1) / 2;
  const pageStart = visiblePageQuantity >= quantity ? range(1, currentPage - 1) : range(currentPage - otherPages, otherPages);
  const pageEnd = visiblePageQuantity >= quantity ? range(currentPage + 1, quantity) : range(currentPage + 1, otherPages);

  useEffect(() => {
    if (totalItems === 0 && currentPage > 1) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page: '1',
      });
    }
  }, [currentPage, itensQuantity, quantity, searchParams, setSearchParams, totalItems]);

  if (quantity < 2) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "end",
        ...style,
      }}
    >
      <Pagination className="m-0">
        {pageStart.length > 1 &&
          visiblePageQuantity <= quantity &&
          pageStart.indexOf(1) < 0 && (
            <>
              <Pagination.First onClick={() => onClick(1)} />
              <Pagination.Prev onClick={() => onClick(currentPage - 1)} />
              <Pagination.Item onClick={() => onClick(1)}>{1}</Pagination.Item>
              <Pagination.Ellipsis />
            </>
          )}

        {pageStart.map((number, index) => (
          <Pagination.Item key={index} onClick={() => onClick(number)}>
            {number}
          </Pagination.Item>
        ))}

        <Pagination.Item active onClick={() => onClick(currentPage)}>
          {currentPage}
        </Pagination.Item>

        {pageEnd.map((number, index) => (
          <Pagination.Item key={index} onClick={() => onClick(number)}>
            {number}
          </Pagination.Item>
        ))}

        {pageEnd.length > 1 &&
          visiblePageQuantity <= quantity &&
          pageEnd.indexOf(quantity) < 0 && (
            <>
              <Pagination.Ellipsis />
              <Pagination.Item onClick={() => onClick(quantity)}>
                {quantity}
              </Pagination.Item>
              <Pagination.Next onClick={() => onClick(currentPage + 1)} />
              <Pagination.Last onClick={() => onClick(quantity)} />
            </>
          )}
      </Pagination>
    </div>
  );
};
