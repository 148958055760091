import { Badge } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "config/yup";
import { DialogModalType } from "features/common/components/DialogModal/DialogModal";
import FormControl from "features/common/components/FormControl";
import List from "features/common/components/List";
import useRequest, { RequestStatus } from "features/common/hooks/useRequest";
import { ValidationResult } from "features/common/models/ValidationResult";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { Shape } from "features/common/types";
import { useProjeto } from "features/projetos/providers/ProjetoProvider";
import createValidacaoEncerramento from "features/projetos/services/validacao/createValidacaoEncerramento";
import { useCallback, useEffect } from "react";
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFetchValidacao } from "./useFetchValidacao";
import { useValidacaoDirty } from "./useValidacaoDirty";
import { ValidacaoDado } from "./ValidacaoDado";
import { useCheckDateValidation } from "./useCheckDateValidation";

export interface ValidacaoEncerramentoForm {
  responsavel: string;
  data: string;
  projetoId: string;
}

export const validacaoEncerramentoSchema = yup.object<Shape<ValidacaoEncerramentoForm>>({
  responsavel: yup.string().max(120).required().label("Responsável"),
  data: yup.string().required().label("Data"),
  projetoId: yup.string().required().label("ProjetoId"),
}).required();

const ValidacoesTab = () => {
  const { getValues, handleSubmit, register, reset, formState: { errors, isDirty } } = useForm<ValidacaoEncerramentoForm>({
    resolver: yupResolver(validacaoEncerramentoSchema),
  });

  const { tabIsDirty, projeto } = useProjeto();

  const { changeIsDirty } = useValidacaoDirty();

  const checkDateValidation = useCheckDateValidation();

  useEffect(() => {
    console.log('change dirty', undefined, isDirty);
    changeIsDirty(isDirty, undefined);
  }, [changeIsDirty, isDirty, tabIsDirty]);

  const {
    progress: { setIsLoading },
    dialog: { toast, setModalShow },
  } = useUIBehaviour();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    validacao,
    codigoProduto,
    envioDocumento,
    atualizacaoDocumento,
    conclusaoProjeto,
  } = useFetchValidacao();

  useEffect(() => {
    reset({
      responsavel: validacao?.responsavel ?? "",
      data: validacao?.data ?? "",
      projetoId: searchParams.get("id") ?? ""
    });
  }, [reset, searchParams, validacao?.data, validacao?.responsavel]);

  const { execute, status, failure } = useRequest<ValidacaoEncerramentoForm, ValidationResult>(createValidacaoEncerramento);

  useEffect(() => {
    setIsLoading(status === RequestStatus.Loading);

    if (status === RequestStatus.Success) {
      toast.success("Cadastrado com sucesso");
      reset(getValues());
      setModalShow({
        type: DialogModalType.Warning,
        content: <span>O projeto somente será concluído ao alterar o status na pagina de Dados do projeto!</span>,
        actions: [
          {
            text: 'Ok',
            onClick: () => {
              setModalShow();
            },
            variant: 'success',
          },
        ],
      })
    }

    if (status === RequestStatus.Failed) {
      toast.error(failure?.notifications?.[0].message ?? "Algo deu errado");
    }
  }, [failure?.notifications, getValues, reset, setIsLoading, setModalShow, status, toast]);

  const onSubmit = useCallback((form: ValidacaoEncerramentoForm) => {
    execute(form);
    checkDateValidation(getValues('data'));
  }, [checkDateValidation, execute, getValues]);

  const onVoltar = useCallback(() => {
    tabIsDirty ? setModalShow({
      type: DialogModalType.Warning,
      content: <span>Deseja descartar alterações?</span>,
      actions: [
        {
          text: 'Não',
          onClick: () => setModalShow(),
          variant: 'outline-success',
        },
        {
          text: 'Descartar',
          onClick: () => {
            navigate('/');
            setModalShow();
          },
          variant: 'success',
        },
      ],
    }) : navigate('/')
  }, [tabIsDirty, navigate, setModalShow]);

  return <div className="AnaliseCriticaTab">
    <div className="mb-4">
      <h4>Validações</h4>
    </div>

    <div className="mb-4 d-flex">
      <h5>Código do Projeto</h5>
      <Badge className="codigo-projeto lg ms-3 d-flex align-self-center">{projeto?.codigoProjeto}</Badge>
    </div>

    <div className="mb-3">
      <h5>Listagem</h5>
    </div>

    <List className="" gap={15}>

      <ValidacaoDado maxCharacters={300} validacaoDado={codigoProduto} changeIsDirty={changeIsDirty} />
      <ValidacaoDado maxCharacters={1000} validacaoDado={envioDocumento} changeIsDirty={changeIsDirty} />
      <ValidacaoDado maxCharacters={1000} validacaoDado={atualizacaoDocumento} changeIsDirty={changeIsDirty} />
      <ValidacaoDado maxCharacters={3000} validacaoDado={conclusaoProjeto} changeIsDirty={changeIsDirty} />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-between mb-3">
          <FormControl
            label='Responsável Encerramento'
            error={errors.responsavel}
            controlId="responsavel"
            style={{ marginBottom: 20 }}>
            <Form.Control
              {...register("responsavel")}
              type="text"
              placeholder="Insira o responsável" />
          </FormControl>

          <FormControl
            label='Data de Encerramento'
            error={errors.data}
            controlId="data"
            style={{ marginBottom: 20 }}>
            <Form.Control
              {...register("data")}
              type="date" />
          </FormControl>
        </div>

        <div className="d-flex justify-content-between">
          <div>
            <Button size="lg" variant="outline-success" onClick={() => onVoltar()}>Voltar</Button>
          </div>

          <div>
            <Button type="submit" size="lg" variant="success">Finalizar Validações</Button>
          </div>
        </div>
      </Form>

    </List>
  </div>;
}

export default ValidacoesTab;