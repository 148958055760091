export enum StatusTex {
  Active = 0,
  StandBy = 1,
  Approved = 2,
  Disapproved = 3,
}

export const mapStatusTexToString = new Map([
  [StatusTex.Active, 'Em Andamento'],
  [StatusTex.StandBy, 'Stand By'],
  [StatusTex.Approved, 'Aprovado'],
  [StatusTex.Disapproved, 'Reprovado'],
]);

export default class Tex {
  public constructor(
    public id: string,
    public numero: number,
    public status: number,
    public descricaoStatus: string,
    public definicao: string,
    public projetoId?: string,
    public dataAbertura?: string,
  ) {}

  public static empty(): Tex {
    return {
      id: '',
      numero: 0,
      status: StatusTex.Active,
      descricaoStatus: '',
      definicao: '',
    }
  }
}