import { FC, ReactNode } from "react";

type ListDirection = 'horizontal' | 'vertical';

interface ListProps {
  children: ReactNode | ReactNode[],
  gap?: number,
  direction?: ListDirection,
  className?: string,
}

const List: FC<ListProps> = ({
  gap,
  children,
  direction,
  className,
}) => {
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexDirection: direction === 'horizontal' ? 'row' : 'column',
        grid: 'inherit',
        gap: gap,
      }}>
      {children}
    </div>
  );
}

export default List;