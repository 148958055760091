export enum StatusAnaliseCritica {
  Aprovado = 0,
  Reprovado = 1,
}

export const mapBgStatusAnaliseCriticaToString = new Map([
  [StatusAnaliseCritica.Aprovado, 'success'],
  [StatusAnaliseCritica.Reprovado, 'danger'],
]);

export const mapStatusAnaliseCriticaToString = new Map([
  [StatusAnaliseCritica.Aprovado, 'Aprovado'],
  [StatusAnaliseCritica.Reprovado, 'Reprovado'],
]);

export class AnaliseCritica {
  public constructor(
    public codigoProjeto: string,
    public codigoReferencia: string,
    public itemExtrusao: string,
    public dataProducao: string,
    public numeroTex: number | undefined,
    public nrTabelaItens: number | undefined,
    public ordemProducao: number | undefined,
    public quantidadeOp: number | undefined,
    public status: StatusAnaliseCritica,
    public id?: string,
    public cliente?: string,
    public avaliacaoProducao?: string,
    public avaliacaoCliente?: string,
    public conclusaoRodada?: string,
    public projetoId?: string,
    public nrAssistTecnica?: number,
  ) { }
}