import axios from "axios";
import { useCallback } from "react";
const { encode } = require('url-encode-decode')

export interface UserDataResponse {
  hasError: boolean;
  data: any;
  error?: any;
}

export const useFetchUserData = (accessToken: string | null) => {
  return useCallback(async (): Promise<UserDataResponse | undefined> => {
    if (!accessToken) return undefined;

    let server = process.env.REACT_APP_Portal_API_URL;
    try {
      const result = await axios.get(
        server + `/Account/AccessToken?accessToken=${encode(accessToken)}`
      );

      if (!result)
        return {
          hasError: false,
          data: undefined,
        };

      const { data, status } = result;

      let hasError = status !== 200;

      return {
        hasError: status !== 200,
        data: !hasError && data,
        error: hasError && data,
      };
    } catch (error) {
      return {
        hasError: true,
        data: undefined,
        error,
      };
    }
  }, [accessToken]);
};
