import axios from "axios";
import { IResponse } from "features/common/hooks/useRequest";
import { ValidationResult } from "features/common/models/ValidationResult";

export interface DeleteRequisitoCommand {
    requisitoId: string;
    projetoId: string;
}

export async function deleteRequisito(command: DeleteRequisitoCommand): Promise<IResponse<ValidationResult>> {
    return await axios.post(`/Requisito/Delete`, command);
}
