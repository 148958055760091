import clsx from "clsx";
import { FC, ReactNode } from "react";
import { Form } from "react-bootstrap";

interface LabeledTextProps {
    label: string;
    children: string | number | undefined | ReactNode;
    className?: string;
}

export const LabeledText: FC<LabeledTextProps> = ({ children, label, className }) => {
    return (
        <div className={clsx("d-flex flex-column", className)}>
            <Form.Label className="mb-0 text-val-gray-800 fs-7">{label}</Form.Label>
            <span className="fs-7 mb-0">{children}</span>
        </div>
    );
};