export interface Validacao {
  data?: string;
  responsavel?: string;
  dados: ValidacaoDado[];
}

interface ValidacaoDadoInitial {
  descricao: undefined;
  data: undefined;
  responsavel: undefined;
  tipo: number;
}

interface ValidacaoDadoFinal {
  descricao: string;
  data: string;
  responsavel: string;
  tipo: number;
}

export type ValidacaoDado = ValidacaoDadoInitial | ValidacaoDadoFinal;

export enum ETipoValidacao {
  DefinicaoCodigoProduto = 0,
  EnvioDocumento = 1,
  AtualizacaoDocumentoRegulamentar = 2,
  ConclusaoProjetoValidacao = 3,
}

export const getValidacaoDadoDescription = (tipo: ETipoValidacao) => {
  if (tipo === ETipoValidacao.DefinicaoCodigoProduto) return { label: "Definição Cod Produto", placeholder: "Insira a definição do código do produto" };
  if (tipo === ETipoValidacao.EnvioDocumento) return { label: "Especificações Elaboradas", placeholder: "Insira o envio de documento" };
  if (tipo === ETipoValidacao.AtualizacaoDocumentoRegulamentar) return { label: "Documentos Regulatórios", placeholder: "Insira a atualização do documento regulamentar" };
  if (tipo === ETipoValidacao.ConclusaoProjetoValidacao) return { label: "Conclusão do Projeto", placeholder: "Insira a conclusão do projeto de validação" };
  return { label: "", placeholder: "" };
};