import { yupResolver } from "@hookform/resolvers/yup";
import yup from "config/yup";
import FormControl from "features/common/components/FormControl";
import FormControlTextArea from "features/common/components/FormControlTextArea";
import useRequest, { RequestStatus } from "features/common/hooks/useRequest";
import { ValidationResult } from "features/common/models/ValidationResult";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { Shape } from "features/common/types";
import { StatusTex } from "features/projetos/models/Tex";
import { saveTex } from "features/projetos/services/tex/saveTex";
import { updateTex } from "features/projetos/services/tex/updateTex";
import { FC, useCallback, useEffect } from "react";
import { Button, Col, Form, Offcanvas, OffcanvasProps } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { TexResponse, useFetchTex } from "./hooks/useFetchTex";
import closeIcon from "../../../../../assets/icon-close.png";

export interface TexDoProjetoOffCanvasProps extends OffcanvasProps {
  projetoId?: string;
  tex?: TexResponse;
}

export interface TexProjetoForm {
  id: string;
  projetoId: string;
  numero: number;
  status: StatusTex;
  definicao: string;
}

const texDoProjetoSchema = yup.object<Shape<TexProjetoForm>>({
  id: yup.string().required(),
  projetoId: yup.string().required(),
  numero: yup.number().required().label("Número do Tex"),
  status: yup.number().required().label("Status"),
  definicao: yup.string().max(500).required().label("Definição"),
}).required();

const TexDoProjetoOffCanvas: FC<TexDoProjetoOffCanvasProps> = ({
  show,
  onHide,
  projetoId,
  tex,
  style,
  ...props
}) => {
  const { handleSubmit, register, reset, formState: { errors } } = useForm<TexProjetoForm>({
    resolver: yupResolver(texDoProjetoSchema)
  });

  const {
    progress: { setIsLoading },
    dialog: { toast },
  } = useUIBehaviour();

  const handleRequest = useCallback((data: TexProjetoForm) => {
    return tex?.status ? updateTex({ ...data, textEditId: tex?.id }) : saveTex(data);
  }, [tex?.id, tex?.status]);

  const { execute, status, failure } = useRequest<TexProjetoForm, ValidationResult>(handleRequest);

  useEffect(() => {
    reset({ ...tex, projetoId });
  }, [projetoId, reset, tex]);

  const onSubmit = useCallback(async (data: TexProjetoForm) => {
    await execute(data);
  }, [execute]);

  useEffect(() => {
    setIsLoading(status === RequestStatus.Loading);

    if (status === RequestStatus.Success) {
      toast.success(tex?.status ? "Atualizado com sucesso" : "Cadastrado com sucesso");
      onHide();
    }

    if (status === RequestStatus.Failed) {
      toast.error(failure?.notifications?.[0].message ?? "Algo deu errado");
    }
  }, [failure?.notifications, onHide, setIsLoading, status, tex?.status, toast]);

  return <Offcanvas
    className="TexDoProjetoOffcanvas"
    {...props}
    style={{ width: 600, ...props.style }}
    show={show}
    placement='end'
    onHide={onHide}>
    <Offcanvas.Header>
      <div className="w-100 d-flex justify-content-between border-bottom pb-2">
        <Offcanvas.Title className="">Inserir TEX's do Projeto</Offcanvas.Title>
        <Button className="cursor-pointer p-0" onClick={onHide} variant="link"><img  alt="Fechar Modal" src={closeIcon} /></Button>
      </div>
    </Offcanvas.Header>
    <Form
      className="TexDoProjetoOffcanvas"
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Offcanvas.Body>
        <Col className="d-grid gap-3">
          <FormControl
            label='Número do TEX'
            error={errors.numero}
            controlId="numero">
            <Form.Control
              {...register("numero")}
              type="text" disabled />
          </FormControl>

          <FormControl
            label='Status'
            error={errors.status}
            controlId="status">
            <Form.Select {...register("status")} defaultValue="">
              <option style={{ display: "none" }} value=""></option>
              <option value={StatusTex.Active}>Em Andamento</option>
              <option value={StatusTex.StandBy}>Stand By</option>
              <option value={StatusTex.Approved}>Aprovado</option>
              <option value={StatusTex.Disapproved}>Reprovado</option>
            </Form.Select>
          </FormControl>

          <FormControl
            label='Definição'
            error={errors.definicao}
            controlId="definicao"
            maxCharacters={500}>
            <FormControlTextArea
              {...register("definicao")}
              placeholder=""
              height={81} />
          </FormControl>
        </Col>
      </Offcanvas.Body>

      <div style={{
        display: 'flex',
        alignItems: 'center',
        background: 'none',
        border: 'none',
        padding: 16,
      }}>
        <Button
          variant="success"
          type="submit"
          disabled={false}>Salvar</Button>
      </div>
    </Form>
  </Offcanvas>;
}

export default TexDoProjetoOffCanvas;