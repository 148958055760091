import { ApplicationPagination } from "features/common/components/ApplicationPagination";
import DropdownActions from "features/common/components/DropdownActions";
import List from "features/common/components/List";
import ListTile from "features/common/components/ListTile";
import ListTileColumn from "features/common/components/ListTileColumn";
import useRequest, { RequestStatus } from "features/common/hooks/useRequest";
import PaginationBase from "features/common/models/PaginationBase";
import { formatDateString, useNavigateWithId } from "features/common/utils";
import Tex, { mapStatusTexToString } from "features/projetos/models/Tex";
import { useProjeto } from "features/projetos/providers/ProjetoProvider";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { useCallback, useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import EmptyListImage from 'assets/empty-list.png';
import TexDoProjetoOffCanvas from "./TexDoProjetoOffcanvas";
import { fetchTexes } from "features/projetos/services/tex/fetchTexes";
import { useFetchTex } from "./hooks/useFetchTex";
import { PagedResult } from "features/common/models/PagedResult";
import TextTruncate from "react-text-truncate";

const TexDoProjetoPage = () => {
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [projetoId, setProjetoId] = useState<string | undefined>(undefined);

  const navigateWithId = useNavigateWithId();

  const { tex, fetchTex } = useFetchTex();

  const {
    progress: { setIsLoading },
    dialog: { toast },
  } = useUIBehaviour();

  const { projeto } = useProjeto();
  const [searchParams] = useSearchParams();

  const { execute, status, data } = useRequest<PaginationBase & { projectId: string }, PagedResult<Tex>>(fetchTexes);
  const [texes, setTexes] = useState<PagedResult<Tex>>();

  useEffect(() => {
    setProjetoId(searchParams.get('id') ?? "");
  }, [searchParams, setProjetoId]);

  const fetchPaginationCallback = useCallback(() => {
    if (projetoId) {
      execute({
        projectId: projetoId,
        page: parseInt(searchParams.get('page') ?? '1'),
        take: 4,
      });
    }
  }, [execute, projetoId, searchParams]);

  useEffect(() => {
    fetchPaginationCallback();
  }, [fetchPaginationCallback]);

  useEffect(() => {
    setIsLoading(status === RequestStatus.Loading);

    if (status === RequestStatus.Success) {
      setTexes(data);
    }

    if (status === RequestStatus.Failed) {
      toast.error('Algo inesperado aconteceu');
    }
  }, [data, setIsLoading, status, toast]);

  return <div className="TexDoProjetoPage">
    <div className="d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <h6 style={{ marginTop: 8 }}>Código do Projeto</h6>
        <Badge
          className="codigo-projeto lg"
          style={{ marginLeft: 15 }}>{projeto?.codigoProjeto}</Badge>
      </div>
    </div>

    <div style={{ height: 20 }}></div>

    <div style={{
      display: 'flex'
    }}>
      <h4>TEX's do Projeto</h4>

      <div style={{ width: 20 }}></div>

      <div>
        <Button
          size="sm"
          variant="primary"
          type="button"
          onClick={() => {
            fetchTex(undefined);
            setShowOffCanvas(true);
          }}>
          Adicionar
        </Button>

        {showOffCanvas && (
          <TexDoProjetoOffCanvas
            tex={tex}
            projetoId={projetoId}
            show={showOffCanvas}
            onHide={() => {
              fetchPaginationCallback();
              setShowOffCanvas(false)
            }} />
        )}
      </div>
    </div>

    <div style={{ height: 20 }}></div>

    <List gap={15}>
      {texes?.records && (texes?.records.length > 0 ? texes.records.map((tex, key) =>
        <ListTile
          key={key}
          end={<DropdownActions
            actions={[
              {
                text: 'Editar',
                onClick: () => {
                  fetchTex(tex.id);
                  setShowOffCanvas(true);
                },
              }
            ]} />}>
          <ListTileColumn label="Nr. Tex" flex={1}>
            <span>{tex.numero}</span>
          </ListTileColumn>

          <ListTileColumn label="Data Abertura" flex={1}>
            {tex.dataAbertura && <span>
              {formatDateString(tex.dataAbertura)}
            </span>}
          </ListTileColumn>

          <ListTileColumn label="Status" flex={1}>
            {mapStatusTexToString.get(tex.status)}
          </ListTileColumn>

          <ListTileColumn label="Definição" flex={4}>
            <TextTruncate text={tex.definicao} line={2} />
          </ListTileColumn>
        </ListTile>) : <div style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <img src={EmptyListImage} alt="Nenhum dado encontrado" />
      </div>)}
    </List>

    <div>
      <ApplicationPagination
        style={{ float: 'right', marginTop: 20 }}
        itensPerPage={4}
        totalItems={texes?.recordsTotal}
        itensQuantity={texes?.records.length ?? 0} />

      <div style={{
        display: 'flex',
        alignItems: 'center',
        paddingTop: 20,
      }}>
        <Button
          size="lg"
          variant="outline-success"
          type="button"
          style={{ width: 146 }}
          onClick={() => navigateWithId('/edit/dados-do-projeto')}>
          Voltar
        </Button>
      </div>
    </div>
  </div>;
}

export default TexDoProjetoPage;