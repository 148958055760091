import DialogModal from "features/common/components/DialogModal";
import { DialogModalProps } from "features/common/components/DialogModal/DialogModal";
import useDialogModal from "features/common/components/DialogModal/useDialogModal";
import FullscreenProgressIndicator, { useFullscreenProgressIndicator } from "features/common/components/FullscreenProgressIndicator";
import useNetworkStatus from "features/common/hooks/useNetworkStatus";
import {
  createContext,
  JSXElementConstructor,
  ReactElement,
  useContext,
  useEffect,
} from "react";
import {
  toast,
  ToastContainer,
} from "react-toastify";
import { WifiOff, Wifi } from 'react-bootstrap-icons';

interface UIBehaviourProps {
  progress: {
    setIsLoading: (value: boolean, text?: string) => void;
  },
  dialog: {
    setModalShow: (dialogModal?: DialogModalProps | undefined) => void;
    toast: typeof toast;
  }
}

const UIBehaviourContext = createContext({} as UIBehaviourProps);

export const UIBehaviourProvider = ({ children }: {
  children?: ReactElement<any, string | JSXElementConstructor<any>> |
  ReactElement<any, string | JSXElementConstructor<any>>[] | undefined;
}) => {
  const {
    setIsLoading,
    isLoading,
    loadingText,
  } = useFullscreenProgressIndicator();
  const {
    modalShow,
    modalTitle,
    modalType,
    modalActions,
    modalContent,
    modalFooter,
    setModalShow,
    onExited,
  } = useDialogModal();
  const internetConnected = useNetworkStatus();

  useEffect(() => {
    if (internetConnected !== undefined) {
      if (internetConnected) {
        toast.dismiss();
        toast('Conexão com internet restaurada', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "dark",
          icon: <Wifi size={20} />,
        });
      } else {
        toast('Sem conexão com a internet', {
          position: "bottom-right",
          autoClose: false,
          closeOnClick: false,
          theme: "dark",
          icon: <WifiOff size={20} />,
        });
      }
    }

  }, [internetConnected]);

  return <UIBehaviourContext.Provider value={{
    progress: { setIsLoading },
    dialog: {
      setModalShow,
      toast,
    },
  }}>
    <ToastContainer
      position="bottom-right"
      autoClose={3000}
      closeOnClick={true}
      pauseOnHover={true}
      draggable={true}
      theme="colored" />
    {isLoading && <FullscreenProgressIndicator text={loadingText} />}
    <DialogModal
      title={modalTitle}
      type={modalType}
      show={modalShow}
      onHide={setModalShow}
      actions={modalActions}
      content={modalContent}
      footer={modalFooter}
      onExited={onExited} />
    {children}
  </UIBehaviourContext.Provider>
}

export function useUIBehaviour() {
  return useContext<UIBehaviourProps>(UIBehaviourContext);
}