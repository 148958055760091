import { useEffect, useState } from "react";
import { CharacterCounterProps } from "./types";
import "./style.scss";
import { appendClassName } from "features/common/utils";

export const CharacterCounter = ({
  maxCharacters,
  caracters,
  className,
  style,
}: CharacterCounterProps) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setCounter(maxCharacters - caracters.length);
  }, [maxCharacters, caracters]);

  const hasError = counter < 0;
  return (
    <div
      className={appendClassName('CharacterCounter position-relative', className)}
      style={{ ...style }}
    >
      <span
        className={appendClassName('', hasError ? 'invalid' : '')}
        style={{ fontSize: "0.65rem" }}
      >
        {counter}
      </span>
    </div>
  );
};
