import { Dropdown } from "react-bootstrap";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import './style.scss';

export interface DropdownAction {
  text: string;
  disabled?: boolean;
  onClick?: () => void;
}

interface DropdownActionsProps {
  actions?: DropdownAction[];
}

const DropdownActions = ({ actions } : DropdownActionsProps) => {
  return <Dropdown className="DropdownActions">
    <Dropdown.Toggle className="DropdownActionsButton" variant="default">
      <ThreeDotsVertical size={20} />
    </Dropdown.Toggle>

    {actions && <Dropdown.Menu>
      {actions.map((action, key) => <Dropdown.Item 
        key={key}
        disabled={action.disabled}
        className="fs-7"
        onClick={action.onClick}>{action.text}</Dropdown.Item>)}
    </Dropdown.Menu>}
  </Dropdown>;
};

export default DropdownActions;