import { today } from "features/common/utils";

export enum TipoAbertura {
  NovoProduto = 0,
  Homologacao = 1,
  Otimizacao = 2,
}

export enum StatusProjeto {
  EmAndamento = 0,
  AprovadoConcluido = 1,
  Cancelado = 2,
  Suspenso = 3,
}

export const mapStatusProjetoToString = new Map([
  [StatusProjeto.EmAndamento, 'Em Andamento'],
  [StatusProjeto.AprovadoConcluido, 'Concluído'],
  [StatusProjeto.Cancelado, 'Cancelado'],
  [StatusProjeto.Suspenso, 'Suspenso'],
]);

export const mapStatusProjetoToColor = new Map([
  [StatusProjeto.EmAndamento, 'warning'],
  [StatusProjeto.AprovadoConcluido, 'success'],
  [StatusProjeto.Cancelado, 'danger'],
  [StatusProjeto.Suspenso, 'secondary'],
]);

export const mapTipoAberturaToString = new Map([
  [TipoAbertura.NovoProduto, 'Novo Produto'],
  [TipoAbertura.Homologacao, 'Homologação MP'],
  [TipoAbertura.Otimizacao, 'Otimização'],
]);

export default class Projeto {
  public constructor(
    public codigoProjeto: string,
    public dataAbertura: string,
    public definicaoProjeto: string,
    public objetivoProjeto: string,
    public usuarioVerificacao: string,
    public divisao: string,
    public dataVerificacao: string,
    public tipoAbertura?: TipoAbertura,
    public status?: StatusProjeto,
    public id?: string,
    public dadosEntrada?: string,
  ) { }

  public static empty(): Projeto {
    return {
      codigoProjeto: '',
      dataAbertura: today(),
      usuarioVerificacao: '',
      divisao: "MG2",
      definicaoProjeto: '',
      objetivoProjeto: '',
      dataVerificacao: '',
    };
  }
}