import axios from "axios";
import PaginationBase from "features/common/models/PaginationBase";

export interface TexsRequest extends PaginationBase {
  projectId: string;
}

export async function fetchTexes(pagination: TexsRequest) {
  pagination.page = pagination.page - 1;

  let queryString = Object.keys(pagination).map(key => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(pagination[key as keyof PaginationBase])
  }).join('&');

  return await axios.get(`/Tex/List?${queryString}`);
}