import clsx from "clsx";
import List from "features/common/components/List";
import useRequest, { RequestStatus } from "features/common/hooks/useRequest";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { AnaliseCritica, StatusAnaliseCritica } from "features/projetos/models/AnaliseCritica";
import { fetchAnaliseCritica } from "features/projetos/services/analise-critica/fetchAnaliseCritica";
import { FC, useEffect } from "react";
import { Button, Col, Container, Offcanvas, Row } from "react-bootstrap";
import closeIcon from "../../../../../../assets/icon-close.png";
import { LabeledText } from "./components/LabeledText";
import { LargeText } from "./components/LargeText/LargeText";


export enum AnaliseCriticaStatus {
    Aprovado = 0,
    Reprovado = 1
}

const mapAnaliseCriticaStatus = new Map([
    [StatusAnaliseCritica.Aprovado, 'Aprovado'],
    [StatusAnaliseCritica.Reprovado, 'Reprovado'],
]);

const mapAnaliseCriticaStatusClass = new Map([
    [StatusAnaliseCritica.Aprovado, 'bg-success'],
    [StatusAnaliseCritica.Reprovado, 'bg-danger'],
]);

export interface VisualizarAnaliseCritica {
    ordemProducao: string;
    itemExtrusao: number;
    codigoReferencia: string;
    nrAssistTecnica: number;
    nrTabelaItens: number;
    cliente: string;
    quantidadeOp: number;
    dataProducao: string;
    numeroTex: string;
    avaliacaoProducao: string;
    conclusaoRodada: string;
    avaliacaoCliente: string;
    status: AnaliseCriticaStatus;
}

interface VisualizarAnaliseCriticaOffCanvasProps {
    analiseId: string;
    setIsVisible: (analiseId: string | undefined) => void;
}

export const VisualizarAnaliseCriticaOffCanvas: FC<VisualizarAnaliseCriticaOffCanvasProps> = ({
    analiseId,
    setIsVisible,
}) => {
    const {
        progress: { setIsLoading },
        dialog: { toast },
    } = useUIBehaviour();

    const { execute, status, data: analise } = useRequest<string, AnaliseCritica>(fetchAnaliseCritica);

    useEffect(() => {
        execute(analiseId);
    }, [analiseId, execute]);

    useEffect(() => {
        setIsLoading(status === RequestStatus.Loading);
    }, [setIsLoading, status]);

    useEffect(() => {
        if (status === RequestStatus.Failed) {
            toast.error("Algo inesperado aconteceu");
        }
    }, [status, toast]);

    return (
        <Offcanvas
            style={{ width: 600, height: "100vh" }}
            show={analiseId}
            placement="end"
            onHide={() => setIsVisible(undefined)}
        >
            <Offcanvas.Header>
                <div className="w-100 d-flex justify-content-between border-bottom pb-2">
                    <Offcanvas.Title className="">Visualizar Dados</Offcanvas.Title>
                    <Button className="cursor-pointer p-0" onClick={() => setIsVisible(undefined)} variant="link"><img alt="Fechar Modal" src={closeIcon} /></Button>
                </div>
            </Offcanvas.Header>

            <Offcanvas.Body className="overflow-auto d-flex flex-column">

                <div className="overflow-auto">
                    <List className="ProjetosList">
                        <div className="my-2">
                            <Container className="d-grid gap-3" fluid>
                                <Row>
                                    <Col>
                                        <LabeledText label="OP">{analise?.ordemProducao}</LabeledText>
                                    </Col>
                                    <Col>
                                        <LabeledText label="Item de Extrusão">{analise?.itemExtrusao}</LabeledText>
                                    </Col>
                                    <Col>
                                        <LabeledText label="Cód. Referência">{analise?.codigoReferencia}</LabeledText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <LabeledText label="Nr. Ass. Téc">{analise?.nrAssistTecnica}</LabeledText>
                                    </Col>
                                    <Col>
                                        <LabeledText label="Nr. Tab. Itens">{analise?.nrTabelaItens}</LabeledText>
                                    </Col>
                                    <Col>
                                        <LabeledText label="Cliente">{analise?.cliente}</LabeledText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <LabeledText label="Quantidade OP">{analise?.quantidadeOp}</LabeledText>
                                    </Col>
                                    <Col>
                                        <LabeledText label="Data Produção">{analise?.dataProducao}</LabeledText>
                                    </Col>
                                    <Col>
                                        <LabeledText label="Número Tex’s">{analise?.numeroTex}</LabeledText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <LargeText label="Avaliação de Produção">{analise?.avaliacaoProducao}</LargeText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <LargeText label="Conclusão da Rodada">{analise?.conclusaoRodada}</LargeText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <LargeText label="Avaliação do Cliente">{analise?.avaliacaoCliente}</LargeText>
                                    </Col>
                                </Row>
                                <Row>
                                    <LabeledText label="Status" className="gap-1">
                                        {analise && <span className={clsx("text-white fs-7 rounded px-2 py-1 mt-3", mapAnaliseCriticaStatusClass.get(analise.status))}>
                                            {mapAnaliseCriticaStatus.get(analise.status)}
                                        </span>}
                                    </LabeledText>
                                </Row>
                            </Container>
                        </div>
                    </List>
                </div>
            </Offcanvas.Body>

        </Offcanvas>
    );
};