import { cloneElement, ReactNode, useEffect, useRef, useState } from "react";
import { Form, FormGroupProps, InputGroup, Spinner } from "react-bootstrap";
import { FieldError } from "react-hook-form";
import { CharacterCounter } from "./CharacterCounter";

const FormControl = ({
  children,
  loading,
  label,
  error,
  maxCharacters,
  ...props
}: {
  children: JSX.Element;
  loading?: boolean;
  label?: ReactNode;
  error?: FieldError;
  maxCharacters?: number;
} & FormGroupProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const [value, setValue] = useState<string>();

  const elementProps = {
    isInvalid: !!error,
    max: children.props.type === "date" ? "9999-12-31" : undefined,
    ref: (e: HTMLInputElement) => {
      const childrenRef = (children as any).ref;
      if (childrenRef) {
        childrenRef(e);
      }
      inputRef.current = e;
    },
    onKeyDown: children.props.type === "number" ? 
      (e: any) => e.keyCode === 69 && e.preventDefault() :
      undefined,
    maxLength: maxCharacters,
  };

  useEffect(() => {
    setValue(inputRef.current?.value);
  }, [children]);

  return <Form.Group {...props} onChange={e => {
    if (props.onChange) props.onChange(e);
    setValue(inputRef.current?.value);
  }}>
    <div className="d-flex gap-1 mb-2 align-items-center">
      {label && <Form.Label className="m-0">{label}</Form.Label>}
      {maxCharacters && (
        <CharacterCounter
          caracters={value || ''}
          maxCharacters={maxCharacters}
        />
      )}
    </div>

    {loading ? <InputGroup>
      {cloneElement(children, {
        ...elementProps,
        style: { borderRight: 'none' },
      })}
      <InputGroup.Text style={{
        backgroundColor: 'transparent',
      }}>
        <Spinner animation="border" size="sm" />
      </InputGroup.Text>
    </InputGroup> : cloneElement(children, elementProps)}

    {error && <Form.Control.Feedback type="invalid">
      {error?.message}
    </Form.Control.Feedback>}
  </Form.Group>
};

export default FormControl;