import { JSXElementConstructor, ReactElement, useCallback, useState } from "react";
import { DialogModalAction, DialogModalProps, DialogModalType, DialogModalFooter } from "./DialogModal";

const useDialogModal = () => {
  const [modalShow, setModalShowState] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>();
  const [modalType, setModalType] = useState<DialogModalType>();
  const [modalFooter, setModalFooter] = useState<DialogModalFooter>();
  const [modalActions, setModalActions] = useState<DialogModalAction[]>();
  const [modalContent, setModalContent] = useState<ReactElement<any, string | JSXElementConstructor<any>> |
    ReactElement<any, string | JSXElementConstructor<any>>[] | undefined>();

  const setModalShow = useCallback((dialogModal?: DialogModalProps) => {
    if (dialogModal) {
      setModalShowState(true);
      setModalTitle(dialogModal.title);
      setModalType(dialogModal.type);
      setModalActions(dialogModal.actions);
      setModalContent(dialogModal.content);
      setModalFooter(dialogModal.footer);
    } else {
      setModalShowState(false);
    }
  }, []);

  function onExited() {
    setModalTitle(undefined);
    setModalType(undefined);
    setModalActions(undefined);
    setModalContent(undefined);
  }

  return {
    modalShow,
    modalTitle,
    modalType,
    modalActions,
    modalContent,
    modalFooter,
    setModalShow,
    onExited,
  }
};

export default useDialogModal;