import React from "react";
import { Form, FormControlProps } from "react-bootstrap";

interface FormControlTextAreaProps {
  height?: number,
  style?: React.CSSProperties,
};

const FormControlTextArea = React.forwardRef((
  props: FormControlProps & FormControlTextAreaProps,
  ref,
) => {
  return <Form.Control
    className="FormControlTextArea"
    ref={ref}
    as="textarea"
    {...props}
    style={{
      height: props.height,
      resize: 'none',
      ...props.style,
    }} />
});

export default FormControlTextArea;