import { useCallback, useState } from "react";

const useFullscreenProgressIndicator = () => {
  const [isLoading, setIsLoadingState] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const setIsLoading = useCallback((value: boolean, text?: string) => {
    setIsLoadingState(value);
    text ? setLoadingText(text) : setLoadingText('');
  }, []);

  return {
    isLoading,
    loadingText,
    setIsLoading,
  }
};

export default useFullscreenProgressIndicator;