import axios from "axios";
import { IResponse } from "features/common/hooks/useRequest";
import PaginationBase from "features/common/models/PaginationBase";
import { PagedResult } from "features/common/models/PagedResult";
import { RequisitoRequest } from "features/projetos/pages/ProjetoEditPage/tabs/Requisitos/RequisitosOffCanvas";
import { Requisito } from "features/projetos/models/Requisito";

export async function fetchRequisitos(data: RequisitoRequest): Promise<IResponse<PagedResult<Requisito>>> {
    data.page = data.page - 1;
    
    const queryString = Object.keys(data).map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key as keyof PaginationBase])
    }).join('&');

    return await axios.get(`/Requisito/List?${queryString}`);
}
