import { AxiosError } from "axios";
import { useCallback, useState } from "react";
import { ValidationResult } from "../models/ValidationResult";

export enum RequestStatus {
  Idle = 'idle',
  Loading = 'loading',
  Success = 'success',
  Failed = 'failed',
};

export interface IResponse<T> {
  data: T;
  error?: any;
}

export interface IUseRequest<TRequest, TResponse> {
  status: RequestStatus;
  data?: TResponse;
  execute: (data: TRequest) => Promise<void>;
  failure?: ValidationResult;
}

export default function useRequest<TRequest, TResponse>(
  requestFunction: (requestData: TRequest) => Promise<IResponse<TResponse>>,
): IUseRequest<TRequest, TResponse> {
  const [status, setStatus] = useState(RequestStatus.Idle);
  const [data, setData] = useState<TResponse>();
  const [failure, setFailure] = useState<ValidationResult>();

  const execute = useCallback(
    async (data: TRequest) => {
      setStatus(RequestStatus.Loading);
      setData(undefined);

      try {
        const response = await requestFunction(data);
        setData(response.data);
        setStatus(RequestStatus.Success);
      } catch (exception) {
        const error = exception as AxiosError<ValidationResult>;
        setFailure(error.response?.data);
        setStatus(RequestStatus.Failed);
      }
    },
    [requestFunction]
  );

  return {
    status,
    data,
    execute,
    failure,
  };
}
