import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Power } from 'react-bootstrap-icons';
import { Link, useNavigate } from "react-router-dom";
import Logo from 'assets/Logo.svg';
import ProfilePic from 'assets/woman-profile-pic.jpg';

const MainNav = () => {
  const navigate = useNavigate();

  return <Navbar className="MainNav shadow-sm px-3" bg="light" expand="lg">
    <Navbar.Brand>
      <Link to="/" style={{
        color: 'initial',
        textDecoration: 'none'
      }}><img src={Logo} /></Link>
    </Navbar.Brand>

    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        {/* <Nav.Link href="#home">Home</Nav.Link>
        <Nav.Link href="#link">Link</Nav.Link>
        <NavDropdown title="Dropdown" id="basic-nav-dropdown">
          <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.2">
            Another action
          </NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">
            Separated link
          </NavDropdown.Item>
        </NavDropdown> */}
      </Nav>
      <Nav className="ms-auto">
        <div className="d-flex d-none">
          <div style={{
            backgroundColor: '#ccc',
            width: 31,
            height: 31,
            borderRadius: 31,
            overflow: 'hidden',
            alignSelf: 'center',
          }}>
            <img style={{
              height: '100%',
              marginLeft: -7,
            }} src={ProfilePic} />
          </div>
          <div style={{ width: 15 }}></div>
          <div style={{
            alignSelf: 'center',
          }}>
            <h6 style={{ marginBottom: -6 }}>Larissa Isabel</h6>
            <small className="text-black-50" style={{ fontSize: 11 }}>Analista</small>
          </div>
        </div>
        <div style={{
          marginLeft: 20,
          marginRight: 10,
          width: 1,
          height: 35,
          backgroundColor: '#B2B2B280',
          alignSelf: 'center',
        }}></div>
        <Nav.Link onClick={() => navigate("/Logout")}>
          <Power color="#2A9D8FB2" size={20} />
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
}

export default MainNav;