import { yupResolver } from "@hookform/resolvers/yup";
import yup from "config/yup";
import { DialogModalProps } from "features/common/components/DialogModal/DialogModal";
import FormControl from "features/common/components/FormControl";
import useRequest, { RequestStatus } from "features/common/hooks/useRequest";
import { ValidationResult } from "features/common/models/ValidationResult";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { Shape } from "features/common/types";
import createRequisito from "features/projetos/services/requisito/createRequisito";
import { useCallback, useEffect, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

export interface RequisitoForm {
    nome: string;
}

export const requisitoSchema = yup.object<Shape<RequisitoForm>>({
    nome: yup.string().max(120).required().label("Nome"),
}).required();

export const useAdicionarRequisitoModal = (realodRequisitos: () => void) => {
    const { reset, register, handleSubmit, formState: { errors } } = useForm<RequisitoForm>({
        resolver: yupResolver(requisitoSchema)
    });

    const { progress: { setIsLoading }, dialog: { setModalShow, toast }, } = useUIBehaviour();

    const submitRequest = useRequest<RequisitoForm, ValidationResult>(data => createRequisito(data));

    const onSubmit = useCallback((form: RequisitoForm) => {
        submitRequest.execute(form);
    }, [submitRequest]);

    useEffect(() => {
        setIsLoading(submitRequest.status === RequestStatus.Loading);
    }, [setIsLoading, submitRequest.status]);


    useEffect(() => {
        if (submitRequest.status === RequestStatus.Success) {
            toast.success('Projeto salvo');
            realodRequisitos();
            setModalShow();
        }

        // Update requisitos list

        if (submitRequest.status === RequestStatus.Failed) {
            const notifications = submitRequest.failure?.notifications;
            if (notifications && notifications.length > 0) {
                toast.error(notifications[0].message);
            }
            else{
                toast.error("Algo deu errado");
            }
        }
    }, [submitRequest.failure?.notifications, submitRequest.status, toast]);

    const modalConfig = useMemo(() => {
        return {
            title: "Novo Requisito",
            content: (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row style={{
                        paddingBottom: 30,
                    }}>
                        <Col className="d-grid gap-3">
                            <FormControl
                                label='Nome'
                                error={errors.nome}
                                maxCharacters={120}
                                controlId="nome">
                                <Form.Control
                                    {...register("nome")}
                                    type="text" 
                                    placeholder="Insira o nome do requisito"
                                    />
                            </FormControl>
                        </Col>
                    </Row>
                </form>),
            footer: {
                className: "d-flex justify-content-between"
            },
            actions: [
                {
                    text: 'Cancelar',
                    onClick: () => {
                        setModalShow();
                    },
                    variant: 'outline-success',
                },
                {
                    text: 'Salvar',
                    onClick: () => {
                        handleSubmit(onSubmit)();
                    },
                    variant: 'success',
                },
            ],
        } as DialogModalProps
    }, [errors.nome, handleSubmit, onSubmit, register, setModalShow]);

    const show = useCallback(() => {
        setModalShow(modalConfig);
        reset();
    }, [modalConfig, reset, setModalShow]);

    const hide = useCallback(() => setModalShow(), [setModalShow]);

    return {
        show,
        hide,
    };
};