import { FC, ReactNode } from "react";
import { Card } from "react-bootstrap";

interface ListTileProps {
  children: ReactNode,
  end?: ReactNode,
}

const ListTile: FC<ListTileProps> = ({ children, end }) => {
  return <Card style={{
    position: 'relative',
  }}>
    <Card.Body>
      <div className="ListTile" style={{
        position: 'relative',
        display: 'flex',
      }}>
        {children}
        {end && <div>
          {end}
        </div>}
      </div>
    </Card.Body>
  </Card>
}

export default ListTile;