import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { userLoggedOut } from "../authSlice";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userLoggedOut());

    setTimeout(() => {
      window.location.replace(process.env.REACT_APP_Portal_URL ?? "");
    }, 500);
  }, [dispatch]);

  return <></>;
};

export default Logout;
