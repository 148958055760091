import { IResponse } from "features/common/hooks/useRequest";
import axios from "axios";
import Entradas from "../../models/Entradas";

export async function fetchEntradas(id: string): Promise<IResponse<Entradas>> {
  const entradas = await axios.get(`/Entrada/${id}`);
  if (entradas.data) {
    entradas.data.dataSolicitacao = entradas.data.dataSolicitacao?.split('T')[0];
    entradas.data.dataPrevistaFinalizacao = entradas.data.dataPrevistaFinalizacao?.split('T')[0];
    entradas.data.dataVerificacao = entradas.data.dataVerificacao?.split('T')[0];
  }
  return entradas;
}