import { useProjeto } from "features/projetos/providers/ProjetoProvider";
import { useCallback, useState } from "react";

export const useValidacaoDirty = () => {
    const [isValidacaoDirty, setIsValidacaoDirty] = useState<boolean[]>([
        false,
        false,
        false,
        false,
        false,
    ]);

    (window as any).isValidacaoDirty = isValidacaoDirty;

    const { setTabIsDirty } = useProjeto();

    const changeIsDirty = useCallback((isDirty: boolean, tipo: number | undefined) => {
        const index = tipo ?? 4;
        if (isValidacaoDirty[index] === isDirty) return;
        
        let dirtyArray = [...isValidacaoDirty];

        dirtyArray[index] = isDirty;

        setIsValidacaoDirty(dirtyArray);

        setTabIsDirty(dirtyArray.some((item) => item));
    }, [isValidacaoDirty, setTabIsDirty]);

    return {
        changeIsDirty
    };
};