import { IResponse } from "features/common/hooks/useRequest";
import PaginationBase from "features/common/models/PaginationBase";
import Projeto, { StatusProjeto, TipoAbertura } from "features/projetos/models/Projeto";
import axios from "axios";
import { PagedResult } from "features/common/models/PagedResult";

export interface ProjetoFilters extends PaginationBase {
  codigo?: string;
  dataAbertura?: string;
  status?: StatusProjeto;
  tipoAbertura?: TipoAbertura;
}

export async function fetchProjetos(pagination: ProjetoFilters):
  Promise<IResponse<PagedResult<Projeto>>> {
  const {
    codigo,
    dataAbertura,
    status,
    tipoAbertura,
    page,
    take,
  } = pagination;

  let pathQuery = `/Projetos/Listar?`;
  pathQuery += queryParam('codigoProjeto', codigo);

  if (dataAbertura && new Date(dataAbertura).getFullYear() > 1900)
    pathQuery += queryParam('dataAbertura', dataAbertura);

  pathQuery += queryParam('status', status?.toString());
  pathQuery += queryParam('tipoAbertura', tipoAbertura?.toString());
  pathQuery += queryParam('page', (page - 1).toString());
  pathQuery += queryParam('take', take.toString());

  return await axios.get(pathQuery);
}

function queryParam(
  name: string,
  value?: string) {
  return value ? `${name}=${value}&` : '';
}