import { DialogModalType } from "features/common/components/DialogModal/DialogModal";
import { useNavigateWithId, usePathUtil } from "features/common/utils";
import { useProjeto } from "features/projetos/providers/ProjetoProvider";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { FC, ReactElement, ReactNode } from "react";
import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";

interface TabsFormTabProps {
  destination: string;
  disabledMessage?: ReactNode;
  children?: ReactElement | ReactElement[] | string;
  valid?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const TabsFormTab: FC<TabsFormTabProps> = ({
  destination,
  children,
  onClick,
  disabled,
  disabledMessage,
}) => {
  const { pathMatches } = usePathUtil();
  const navigateWithId = useNavigateWithId();
  const { dialog: { setModalShow } } = useUIBehaviour();
  const { tabIsDirty, setTabIsDirty } = useProjeto();

  function tabClickHandler() {
    navigateWithId(destination);
    onClick && onClick();
  }

  const navItem = <Nav.Item>
    <Nav.Link
      style={{
        color: 'initial',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: disabled ? .5 : 1,
      }}
      active={pathMatches(destination)}
      disabled={disabled}
      onClick={() => {
        if (tabIsDirty) {
          setModalShow({
            type: DialogModalType.Warning,
            content: <span>Deseja descartar alterações?</span>,
            actions: [
              {
                text: 'Não',
                onClick: () => setModalShow(),
                variant: 'outline-success',
              },
              {
                text: 'Descartar',
                onClick: () => {
                  setTabIsDirty(false);
                  tabClickHandler();
                  setModalShow();
                },
                variant: 'success',
              },
            ],
          });
        } else {
          tabClickHandler();
        }
      }}>
      {children}
    </Nav.Link>
  </Nav.Item>;

  return disabled ? <OverlayTrigger
    key='top'
    placement='top'
    overlay={
      <Tooltip>
        {disabledMessage}
      </Tooltip>
    }>
    {navItem}
  </OverlayTrigger> : navItem;
}